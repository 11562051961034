import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, ReplaySubject } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { OAuthConfig } from 'src/environments/environment';

// Isn't it silly to try to make one of these auth services on our own? So, we won't.
// https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/blob/master/src/app/core/auth.service.ts
@Injectable({ providedIn: 'root' })
export class CommunityAuthService {

  constructor(
    private oauthService: OAuthService,
    private router: Router,
  ) {
    // Useful for debugging:
    this.oauthService.events.subscribe(event => {
      if (event instanceof OAuthErrorEvent) {
        console.error(event);
      } else {
        //console.warn(event);
      }
    });

    // This is tricky, as it might cause race conditions (where access_token is set in another
    // tab before everything is said and done there.
    // TODO: Improve this setup.
    window.addEventListener('storage', (event) => {
      // The `key` is `null` if the event was caused by `.clear()`
      if (event.key !== 'access_token' && event.key !== null) {
        return;
      }

      console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());

      if (!this.oauthService.hasValidAccessToken()) {
        this.navigateToLoginPage();
      }
    });

    this.oauthService.events
      .subscribe(_ => {
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
      });

    this.oauthService.events
      .pipe(filter(e => ['token_received'].includes(e.type)))
      .subscribe(e => this.oauthService.loadUserProfile());

    this.oauthService.events
      .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
      .subscribe(e => this.navigateToLoginPage());

    this.oauthService.setupAutomaticSilentRefresh();
    this.isAuthenticatedSubject$ = new BehaviorSubject<boolean>(this.isAuthenticated());
    this.isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
    this.canActivateProtectedRoutes$ = combineLatest([
      this.isAuthenticated$,
      this.isDoneLoading$
    ]).pipe(map(values => values.every(b => b)));    
  }

  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this.oauthService.getAccessToken(); }
  public get refreshToken() { return this.oauthService.getRefreshToken(); }
  public get identityClaims():  {[key: string]: any} { return this.oauthService?.getIdentityClaims(); }
  public get idToken() { return this.oauthService.getIdToken(); }
  public get logoutUrl() { return this.oauthService.logoutUrl; }

  private isAuthenticatedSubject$ : BehaviorSubject<boolean>;
  public isAuthenticated$ : Observable<boolean>;
  public isAuthenticated() { return this.oauthService.hasValidAccessToken(); }

  private isDoneLoadingSubject$ = new ReplaySubject<boolean>();
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();

  private isImpersonatedSubject$ = new BehaviorSubject<boolean>(false);
  public isImpersonated = this.isImpersonatedSubject$.asObservable();

  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean>;

  private navigateToLoginPage() {
    // TODO: Remember current URL
    this.router.navigateByUrl('/should-login')
  }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()
      .then(() => this.oauthService.tryLogin())
      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      //.then(() => this.oauthService.tryLoginCodeFlow())
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve();
        }

        // We can't handle the truth, just pass on the problem to the
        // next handler.
        //console.error("invalid login token")
        return Promise.reject();
      })

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            stateUrl = decodeURIComponent(stateUrl);
          }
          console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch((err) => { 
        console.warn(err, arguments);
        this.isDoneLoadingSubject$.next(true);  
      });
  }

  public login(targetUrl?: string, noState?: boolean) {
    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    if (!noState)
    {
      this.oauthService.initLoginFlow(targetUrl || this.router.url);
      return;
    }
    this.oauthService.initCodeFlow();
  }

  public logout() { this.oauthService.revokeTokenAndLogout(); this.oauthService.logOut(); }
  public refresh() { this.oauthService.silentRefresh(); }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }

  
  // ITS-added:
  impersonationKey = 'impersonatedHawkId';
  public get isImpersonating() { return !!sessionStorage[this.impersonationKey]; }
  public get impersonatedHawkId() { return sessionStorage[this.impersonationKey]; }
  public get applicationUsername(): string { return this.isImpersonating ? this.impersonatedHawkId : this.actualUsername; }
  public get actualUsername(): string { return this.identityClaims ? this.identityClaims["uiowahawkid"] : null; }
  public endImpersonation() {
    sessionStorage.removeItem('impersonatedHawkId');
    this.isImpersonatedSubject$.next(false);
  }
  public impersonate(hawkId: string) {
    sessionStorage["impersonatedHawkId"] = hawkId;
    this.isImpersonatedSubject$.next(true);
  }
}
