import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CommunityAuthService } from 'src/app/services/community-auth.service';

@Component({
  selector: 'app-session-expired',
  templateUrl: './session-expired.component.html',
  styleUrls: ['./session-expired.component.scss']
})
export class SessionExpiredComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<SessionExpiredComponent>,
    private _router: Router,
    private _authService: CommunityAuthService
  ) { }

  ngOnInit(): void {
  }

  staySignedOut(): void {
    this._authService.logout();
    this.dialogRef.close();
  }

  login(): void {
    const returnTo = this._router.url.startsWith("/computer") ? this._router.url : "/computers";
    this._authService.login(returnTo);
    this.dialogRef.close();
  }
}
