import { Injectable } from "@angular/core";
import { firstValueFrom } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { apiConfiguration } from 'src/environments/environment';
import { AdSoftware, Software, SoftwareDeployType, SyncedSoftwareSummary } from "../models/software";

@Injectable()
export class SoftwareService  {
    private _deployTypeUrl = '../../../../assets/software-deploy-type.json';

    constructor(private _http: HttpClient)
    {}
    
    async fetchSoftwareForComputer({ deployType, computerName }: { deployType: string; computerName: string; }) {
        try {
            const url = apiConfiguration.apiBaseUrl + "software/"+computerName+"/"+deployType;
            const sw = await firstValueFrom(this._http.get<AdSoftware>(url));
            return [sw.softwareAll, sw.softwareSynced] 
        } catch (err) {
            console.error(err);
            return [new Array<Software>(), new Array<Software>()];
        }
    }

    async fetchSoftwareForGroup({ deployType, orgName }: { deployType: string; orgName: string; }) {
        try {
            const url = apiConfiguration.apiBaseUrl + "software/org/"+orgName+"/"+deployType;
            const sw = await firstValueFrom(this._http.get<AdSoftware>(url));
            return [sw.softwareAll, sw.softwareSynced] 
        } catch (err) {
            console.error(err);
            return [new Array<Software>(), new Array<Software>()];
        }
    }

    async fetchAllSyncedSoftware({ computerName }: { computerName: string; }): Promise<SyncedSoftwareSummary> {
        try {
            const url = apiConfiguration.apiBaseUrl + "software/"+computerName;
            return await firstValueFrom(this._http.get<SyncedSoftwareSummary>(url));
        } catch (err) {
           console.error(err);
           return new SyncedSoftwareSummary();
        }
    }

    async syncSoftware({ computerId, deployType, softwareName }: { computerId: string; deployType: string; softwareName: string[]; }): Promise<void> {
        try {
            const url = `${apiConfiguration.apiBaseUrl}software`;
            const model = {computerName: computerId, deployType: deployType, softwareName: softwareName }
            await firstValueFrom(this._http.put(url, model));
        } catch (err) {
            console.error(err);
        }
    }

    async fetchDeployType() {
        return fetch(this._deployTypeUrl).then(res => res.json());
    }
    
    async fetchSoftwareForOrg({ deployType, orgName }: { deployType: string; orgName: string; }) {
        console.log(orgName);
        try {
            const url = apiConfiguration.apiBaseUrl + "software/org/"+orgName+"/"+deployType;
            const sw = await firstValueFrom(this._http.get<AdSoftware>(url));
            return [sw.softwareAll, sw.softwareSynced] 
        } catch (err) {
            console.error(err);
            return [new Array<Software>(), new Array<Software>()];
        }
    }

    async syncSoftwareOrg({ groupName, deployType, softwareName }: { groupName: string; deployType: string; softwareName: string[]; }): Promise<void> {
        try {
            const url = `${apiConfiguration.apiBaseUrl}software/org`;
            const model = {groupName: groupName, deployType: deployType, softwareName: softwareName}
            console.log('syncsoftwareorg', groupName, url)

            await firstValueFrom(this._http.put(url, model));
        } catch (err) {
            console.error(err);
        }
    }

    async fetchAllSyncedSoftwareOrg({ orgName }: { orgName: string; }): Promise<SyncedSoftwareSummary> {
        try {
            console.log("fetchallsyncedsoftwareorg", orgName);
            const url = apiConfiguration.apiBaseUrl + "software/org/"+orgName;
            console.log(url);
            const sw = await firstValueFrom(this._http.get<SyncedSoftwareSummary>(url));
            return sw;
        } catch (err) {
           console.error(err);
           return new SyncedSoftwareSummary();
        }
    }
    
    async moveSelectedSoftwareToSelectedList(selected: string[], source:Software[], sourceDisplay:Software[], dest:Software[]) {
        selected.forEach((item) => {
            let one = source.find(x=>x.name==item)
            if (one != null){
              if (dest.indexOf(one)<0)
                dest.push(one);

              const i = source.indexOf(one)??-1;
              if (i>=0)
                source.splice(i, 1);

              const j = sourceDisplay.indexOf(one)??-1;
              if (i>=0)
                sourceDisplay.splice(j, 1);
            }
          })
          dest = [...new Set(dest)].sort((a,b)=>{return (a.name??'') >= (b.name??'') ? 1:-1});
          return [source, sourceDisplay, dest]
    }

    async moveSelectedSoftwareToAvailableList(selected: string[], source:Software[], dest:Software[], destDisplay:Software[]) {
        selected.forEach((item) => {
            let one = source.find(x=>x.name==item)
            if (one != null){
              if (dest.indexOf(one)<0)
                dest.push(one);

              if (destDisplay.indexOf(one)<0)
                destDisplay.push(one);
    
              let i = source.indexOf(one)??-1;
              if (i>=0)
                source.splice(i, 1);
            }
          })
          destDisplay = [...new Set(destDisplay)].sort((a,b)=>{return (a.name??'') >= (b.name??'') ? 1:-1});
          dest = [...new Set(dest)].sort((a,b)=>{return (a.name??'') >= (b.name??'') ? 1:-1});

          return [dest, destDisplay,source]
    }
}
