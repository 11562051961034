import { ClassProvider, NgModule, ValueProvider } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrandingBarComponent } from './components/branding-bar/branding-bar.component';
import { ErrorSnackComponent } from './components/dialog-bodies/error-snack/error-snack.component';
import { SessionExpiredComponent } from './components/dialog-bodies/session-expired/session-expired.component';
import { MiscErrorComponent } from './components/errors/misc/misc.component';
import { PageNotFoundComponent } from './components/errors/page-not-found/page-not-found.component';
import { HelpComponent } from './components/help/help.component';
import { LoggedOutComponent } from './components/logged-out/logged-out.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { HelpdeskLinkComponent } from './components/widgets/helpdesk-link/helpdesk-link.component';
import { LoadingComponent } from './components/widgets/loading/loading.component';
import { TitleBarComponent } from './components/widgets/title-bar/title-bar.component';
import { ComputerChooserComponent } from './components/computer-chooser/computer-chooser.component';
import { MaterialModule } from './shared/modules/material/material.module';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { apiConfiguration, OAuthConfig } from 'src/environments/environment';
import { CommunityAuthService } from './services/community-auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthGuard } from './guards/auth.guard';
import { ComputerDetailsComponent } from './components/dialog-bodies/computer-details/computer-details.component';
import { RoleGuard } from './guards/role.guard';
import { AuthHeaderInterceptor } from './interceptors/auth-header.interceptor';
import { OrgStructureResolver } from './resolvers/org-structure.resolver';
import { EditOrgStructureComponent } from './components/dialog-bodies/edit-org-structure/edit-org-structure.component';
import { OrgStructurePipesFactory } from './services/org-struct-pipes.factory';
import { EditSubscriptionsComponent } from './components/dialog-bodies/edit-subscriptions/edit-subscriptions.component';
import { SubscriptionResolver } from './resolvers/subscription.resolver';
import { ComputerResolver } from './resolvers/computer.resolver';
import { ComputersResolver } from './resolvers/computers.resolver';
import { RouterModule } from '@angular/router';
import { SettingsComponent } from './components/settings/settings.component';
import { UserResolver } from './resolvers/user.resolver';
import { UserToolBarComponent } from './components/user-tool-bar/user-tool-bar.component';
import { ConfirmComponent } from './components/dialog-bodies/confirm/confirm.component';
import { UserWithFallbackResolver } from './resolvers/user-with-fallback.resolver';
import { ConfirmChangesComponent } from './components/dialog-bodies/confirm-changes/confirm-changes.component';
import { ConfirmSoftwareChangesComponent } from './components/dialog-bodies/confirm-software-changes/confirm-software-changes.component';
import { MemberOfUtilityService } from './services/memberof-utility.service';
import { SoftwareDeployToComputerComponent } from './components/dialog-bodies/software-deploy-to-computer/software-deploy-to-computer.component';
import { SoftwareService } from './services/software.service';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {CdkVirtualScrollViewport} from "@angular/cdk/scrolling";
import { SoftwareSummaryComponent } from './components/dialog-bodies/software-summary/software-summary.component';
import { OrgGroupChooserComponent } from './components/org-chooser/org-chooser.component';
import { SoftwareDeployToOrgComponent } from './components/dialog-bodies/software-deploy-to-org/software-deploy-to-org.component'; 
import { OrgDetailsComponent } from './components/dialog-bodies/org-details/org-details.component';
import { OrgGroupResolver } from './resolvers/org-group.resolver';
import { OrgGroupsResolver } from './resolvers/org-groups.resolver';
import { ConfirmSoftwareChangesOrgComponent } from './components/dialog-bodies/confirm-software-changes-org/confirm-software-changes-org.component';
import { SoftwareSummaryOrgComponent } from './components/dialog-bodies/software-summary-org/software-summary-org.component';

export function storageFactory(): OAuthStorage {
  return localStorage;
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    TitleBarComponent,
    MiscErrorComponent,
    HelpComponent,
    HelpdeskLinkComponent,
    LoggedOutComponent,
    WelcomeComponent,
    BrandingBarComponent,
    SessionExpiredComponent,
    ErrorSnackComponent,
    LoadingComponent,
    ComputerChooserComponent,
    ComputerDetailsComponent,
    EditOrgStructureComponent,
    EditSubscriptionsComponent,
    SettingsComponent,
    UserToolBarComponent,
    ConfirmComponent,
    ConfirmChangesComponent,
    ConfirmSoftwareChangesComponent,
    SoftwareDeployToComputerComponent,
    SoftwareSummaryComponent,
    OrgGroupChooserComponent,
    SoftwareDeployToOrgComponent,
    OrgDetailsComponent,
    ConfirmSoftwareChangesOrgComponent,
    SoftwareSummaryOrgComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    MaterialModule,
    OAuthModule.forRoot({ resourceServer: { allowedUrls: [apiConfiguration.apiBaseUrl], sendAccessToken: true }}),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    BrowserAnimationsModule,
    ScrollingModule
  ],
  providers: [
    CommunityAuthService,
    MemberOfUtilityService,
    SoftwareService,
    { provide: OAuthStorage, useFactory: storageFactory },
    <ValueProvider>{ provide: "authConfig", useValue: OAuthConfig },
    <ClassProvider>{ provide: HTTP_INTERCEPTORS, useClass: AuthHeaderInterceptor, multi: true },
    AuthGuard,
    RoleGuard,
    OrgStructureResolver,
    SubscriptionResolver,
    UserResolver,
    UserWithFallbackResolver,
    ComputerResolver,
    ComputersResolver,
    OrgGroupResolver,
    OrgGroupsResolver,
    OrgStructurePipesFactory
   ],
  bootstrap: [AppComponent]
})
export class AppModule { }
