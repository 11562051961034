import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatLegacyRadioModule as MatRadio } from '@angular/material/legacy-radio';
import { ActiveUser } from 'src/app/models/active-user';
import { Computer } from 'src/app/models/computer';
import { SoftwareService } from 'src/app/services/software.service';
import { Subject, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { ConfirmSoftwareChangesComponent } from '../confirm-software-changes/confirm-software-changes.component';
import { Software, SoftwareDeployType } from 'src/app/models/software';
import { SoftwareSummaryComponent } from '../software-summary/software-summary.component';
import { OrgGroup } from 'src/app/models/org-group';
import { ConfirmSoftwareChangesOrgComponent } from '../confirm-software-changes-org/confirm-software-changes-org.component';
import { SoftwareSummaryOrgComponent } from '../software-summary-org/software-summary-org.component';

@Component({
  selector: 'app-software-deploy-to-org',
  templateUrl: './software-deploy-to-org.component.html',
  styleUrls: ['./software-deploy-to-org.component.scss']
})
export class SoftwareDeployToOrgComponent implements OnInit {
  title: string = "Deploy software to org";
  org!: string;
  orgs!: OrgGroup[];
  orgsDescription!: string;
  form!: FormGroup;
  showSetDesc: boolean = false;
  computedDesc: string = "";
  user: ActiveUser;
  updateEnabled: boolean = false;
  softwareDeployType: string="APD-*-AVL";
  softwareDeployTypes: SoftwareDeployType[] = [];
  softwareSource: Software[] = [];
  softwareSourceDisplay: Software[] = [];
  swToAdd: string[] = [];
  softwareDest: Software[] = [];
  swToRemove: string[] = [];
  modelChanged: Subject<string> = new Subject<string>();
  search = '';
  public isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { orgs: OrgGroup[], title: string, user: ActiveUser },
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<SoftwareDeployToOrgComponent>,
    private _dialog: MatDialog,
    private _softwareService : SoftwareService
  ) {
    this.orgs = _data.orgs;
    this.user = _data.user;
    console.log(this.user, this.user.hasAnyRole("CanWrite"));
    this.orgsDescription = this.orgs.map(c => c.name).join(", ");
    console.log(_data.orgs[0]);
    
    this.form = this._fb.group({
      orgName: [_data.orgs[0].id, { nonNullable: true }],
      description: [_data.orgs[0].description, { nonNullable: true }],
      adLocation: ['', {nonNullable: true}]
      // adLocation: [this.simplifyLocation(_data.orgs[0].distinguishedName ), { nonNullable: true }]
    })
    console.log('form');

  }

  async ngOnInit() {
    console.log('init');
    this.softwareDeployTypes = await this._softwareService.fetchDeployType();
    try {
      await this.loadSoftware();
      this.showSetDesc = true;
      this.modelChanged.pipe(debounceTime(300),distinctUntilChanged())
                       .subscribe(() => {this.searchFor();});
    } catch (ex) {
      console.log(ex)
      this.showSetDesc = false;
    }
  }

  simplifyLocation = (cn: string): string => cn.split(',')
    .map((p: string) => p.replace('OU=Windows', '')
      .replace('OU=Devices', '')
      .replace('OU=Devices', '')
      .replace('DC=iowa', '')
      .replace('DC=uiowa', '')
      .replace('DC=edu', '')
      .replace('OU=', '')
      .replace('CN=', ''))
    .filter(p => p != '')
    .reverse()
    .join('/');

  onSoftwareDeployTypeChange = () => this.loadSoftware();

  async loadSoftware(){
    console.log('load');
    this.isLoading = true;
    try{
      this.softwareSource=[];
      this.softwareDest=[];
      if (this.softwareDeployType != '' && (this.orgs[0].name != null)){
        console.log('not empty');
        [this.softwareSource,this.softwareDest] = await this._softwareService.fetchSoftwareForOrg({ deployType: this.softwareDeployType, orgName: this.orgs[0].name });
        this.softwareSourceDisplay = this.softwareSource = this.softwareSource.filter(p => !this.softwareDest.find(m=>m.name==p.name) );
      }
    } catch (ex) {
      console.log(ex)
    }
    this.isLoading = false;
  }

  searchFor() {
    if (this.search.length >=1 || this.search.length === 0) {
      this.softwareSourceDisplay = this.softwareSource.filter(p => (p.description?.toLowerCase().indexOf(this.search.toLowerCase())>=0) );
    }
  }

  async summary() {
    console.log('summary: ', this.orgs[0].id);

    var updated = await firstValueFrom
      (this._dialog.open(SoftwareSummaryOrgComponent, { data: {groupName: this.orgs[0].id}}).afterClosed());

    if (updated?.length > 0)
      this.dismiss(updated);
  }

  dismiss(result?: any) {
    this._dialogRef.close(result);
  }

  reset () {
    this.search = '';
    this.swToAdd = [];
    this.swToRemove = [];
    this.loadSoftware();  
  } 

  changeModel(event: any) {
    this.modelChanged.next(event.target.value);
  }

  async save() {
      let deploymentType = this.softwareDeployTypes.find(p=>p.type==this.softwareDeployType);
      var updated = await firstValueFrom
        (this._dialog.open(ConfirmSoftwareChangesOrgComponent, { data: {software: this.softwareDest, groupId: this.orgs[0].id, deployType: deploymentType }}).afterClosed());

      if (updated?.length > 0)
        this.dismiss(updated);
  }

  onSelect(){
    this.swToAdd.forEach((item) => {
      let one = this.softwareSource.find(x=>x.name==item)
      if (one != null){
        if (this.softwareDest.indexOf(one)<0)
          this.softwareDest.push(one);
        const i = this.softwareSource.indexOf(one)??-1;
        if (i>=0){
          this.softwareSource.splice(i, 1);
          this.softwareSourceDisplay.splice(i, 1);
        }
      }
      this.softwareDest = [...new Set(this.softwareDest)].sort((a,b)=>{return (a.name??'') >= (b.name??'') ? 1:-1});
    })
  } 

  onDeselect(){
    this.swToRemove.forEach((item) => {
      let one = this.softwareDest.find(x=>x.name==item)
      if (one != null){
        if (this.softwareSource.indexOf(one)<0) {
          this.softwareSource.push(one);
          this.softwareSourceDisplay.push(one);
        }
        let i = this.softwareDest.indexOf(one)??-1;
        if (i>=0)
          this.softwareDest.splice(i, 1);
      }
      this.softwareSourceDisplay = [...new Set(this.softwareSourceDisplay)].sort((a,b)=>{return (a.name??'') >= (b.name??'') ? 1:-1});
      this.softwareSource = [...new Set(this.softwareSourceDisplay)].sort((a,b)=>{return (a.name??'') >= (b.name??'') ? 1:-1});
    })
  }
}
