import { AuthConfig } from 'angular-oauth2-oidc';

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  useExternalHome: false,
  home: '/welcome',
  
  timeoutHelpDelay: 10000 /* milliseconds to wait before showing "this is taking unusually long" message */
};

export const apiConfiguration = {
  apiBaseUrl: "https://eust-test.iowa.uiowa.edu/api/",
  userSearchEndpoint: 'user/search'
};

export const OAuthConfig: AuthConfig = {
  clientId: "ecm-eustools-test-2C4B806E7B5542C5A3D1A04B2A737F3D",
  scope: "openid profile uiowa.identifiers offline_access",
  responseType: 'code',
  redirectUri: "https://eust-test.iowa.uiowa.edu",
  clearHashAfterLogin: true,
  issuer: "https://idp-test.uiowa.edu",
  tokenEndpoint: "https://idp-test.uiowa.edu/idp/profile/oidc/token",
  loginUrl: "https://idp-test.uiowa.edu/idp/profile/oidc/authorize",
  logoutUrl: "https://idp-test.uiowa.edu/idp/profile/Logout"
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
