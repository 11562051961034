<app-user-tool-bar [user]="user"></app-user-tool-bar>
<div class="landing-container">
    <div class="landing-body">
        <div class="col-md-offset-2 col-md-4">
            <div class="panel panel-default" [formGroup]='form'>
                <div class="panel-heading" fxLayout="col" fxLayoutAlign="start center">
                    <mat-icon>settings</mat-icon>
                    <h4>User Settings</h4>
                </div>
                <div class="panel-body" fxLayout="column">
                    <!--<mat-form-field fxFill fxFlex.gt-sm="100%">
                        <input type="text" placeholder='Filter registered Hawk Ids' matInput formControlName="hawkId">
                        <button *ngIf="form.get('hawkId')?.value" matSuffix mat-icon-button type="button" aria-label="Clear"
                            (click)="form.get('hawkId')?.setValue(null); $event.stopPropagation()">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>-->
                    <table class="table-computers" mat-table [dataSource]='registeredUsers' matSort
                        matSortDirection="asc" matSortActive="hawkid" multiTemplateDataRows>
                        <colgroup>
                            <col width="84%" style='min-width: 12em' />
                            <col width="4%" style='min-width: 4em' />
                            <col width="4%" style='min-width: 4em' />
                        </colgroup>
                        <ng-container matColumnDef="hawkid">
                            <th mat-header-cell *matHeaderCellDef>Hawk Id</th>
                            <td mat-cell *matCellDef="let row">
                                {{ row.hawkId }}
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="isadmin">
                            <th mat-header-cell *matHeaderCellDef>Is Admin<sup>1</sup></th>
                            <td mat-cell *matCellDef="let row">
                                <mat-checkbox [checked]="row.isAdmin" (change)="toggleAdmin(row, $event)"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="tools">
                            <th mat-header-cell *matHeaderCellDef>Remove<sup>2</sup></th>
                            <td mat-cell *matCellDef="let row">
                                <button mat-icon-button (click)="removeUser(row)">
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef='footer-pagination'>
                            <td mat-footer-cell *matFooterCellDef class="footer" colspan="7">
                                <section fxLayout="row">
                                    <mat-paginator fxFlex fxLayoutAlign="center center"
                                        [pageSizeOptions]="[5, 10, 20, 50, 100]" [pageSize]="10">
                                    </mat-paginator>
                                </section>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="new-hawkid">
                            <td mat-footer-cell *matFooterCellDef style="text-align: left">
                                <mat-form-field>
                                    <input type="text" placeholder='ahawkid' matInput formControlName="hawkId">
                                </mat-form-field>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="is-new-admin">
                            <td mat-footer-cell *matFooterCellDef>
                                <mat-checkbox formControlName="isNewAdmin"></mat-checkbox>
                            </td>
                        </ng-container>
                        <ng-container matColumnDef="add-new-hawkid">
                            <td mat-footer-cell *matFooterCellDef>
                                <button mat-icon-button type="button" aria-label="Add" (click)="addHawkId(form.get('hawkId')?.value, form.get('isNewAdmin')?.value)">
                                    <mat-icon>add</mat-icon>
                                </button>
                            </td>
                        </ng-container>
                        <tr class="table-header" mat-header-row
                            *matHeaderRowDef="['hawkid','isadmin', 'tools']; sticky: true"></tr>
                        <tr class="table-row" mat-row
                            *matRowDef="let row; let i = dataIndex; columns: ['hawkid','isadmin','tools'];"></tr>
                        <tr class="table-row" *matNoDataRow>
                            <td colspan="7" class="no-data">
                                <h3>No results</h3>
                                <p>Please add any appropriate users who need write or admin access.
                                </p>
                            </td>
                        </tr>
                        <tr mat-footer-row *matFooterRowDef="['new-hawkid', 'is-new-admin', 'add-new-hawkid']"></tr>
                        <!--<tr mat-footer-row *matFooterRowDef="['footer-pagination']; sticky: true"></tr>-->
                    </table>
                    <div class="key" style="font-size:smaller">
                        <h4>Footnotes</h4>
                        <ol>
                            <li>Administrators can register or de-register users, change computer settings, or alter user permissions.</li>
                            <li>Removed users cannot change computer settings but can still log in to view them since this information is public.</li>
                        </ol>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="end center">
                        <a (click)="location.back()" mat-button><mat-icon>arrow_back</mat-icon>&nbsp;Return</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>