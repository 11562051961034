import { Component, Input, OnInit, NgZone } from '@angular/core';
import { interval } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  @Input() message: string = "Please wait...";
  @Input() timeoutMessage: string = "This is taking an unusually long time. If the problem persists, please contact the ITS Help Desk.";

  /** Timeout in milliseconds before showing timeoutMessage. */
  @Input() timeout: number = environment.timeoutHelpDelay;
  
  showTimeoutMessage = false;

  constructor(
    private _ngZone: NgZone    
  ) {
  }

  ngOnInit(): void {
    this._ngZone.runOutsideAngular(() =>  {
      interval(this.timeout).subscribe(_ =>
        this.showTimeoutMessage = true
      );
      });
  }

  ngAfterContentInit() {

  }

}
