<mat-dialog-content>
    <h2>{{ title }}</h2>
    <div class="dialog-body">
        <div *ngIf="isLoading; else done">
            <mat-spinner color="primary" diameters="300" class="spinner" >
            </mat-spinner>
        </div>
        <ng-template #done>
            <section *ngIf="syncedSoftware.mandatory.length>0 || syncedSoftware.asap.length>0 || syncedSoftware.available.length>0 || syncedSoftware.exclude.length>0; else nothing">
            <h3>Software have been synced to {{groupName}}:</h3>
            <div *ngIf="syncedSoftware.exclude.length>0">
                <div>Deployment Type: {{softwareDeployTypes[0].label}}</div>
                    <ul>
                        <li *ngFor="let sw of syncedSoftware.exclude">
                            {{ sw.description }}
                        </li>
                    </ul>
            </div>
            <div *ngIf="syncedSoftware.available.length>0">
                <div>Deployment Type: {{softwareDeployTypes[1].label}}</div>
                <ul>
                    <li *ngFor="let sw of syncedSoftware.available">
                        {{ sw.description }}
                    </li>
                </ul>
            </div>
            <div *ngIf="syncedSoftware.mandatory.length>0">
                <div>Deployment Type: {{softwareDeployTypes[2].label}}</div>
                <ul>
                    <li *ngFor="let sw of syncedSoftware.mandatory">
                        {{ sw.description }}
                    </li>
                </ul>
            </div>
            <div *ngIf="syncedSoftware.asap.length>0">
                <div>Deployment Type: {{softwareDeployTypes[3].label}}</div>
                <ul>
                    <li *ngFor="let sw of syncedSoftware.asap">
                        {{ sw.description }}
                    </li>
                </ul>
            </div>
        </section>
        <ng-template #nothing>
            <h3>No software has been synced to {{groupName}}.</h3>
        </ng-template>
    </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions">
    <button mat-raised-button (click)='cancel()'>{{ closeText }}</button>
</mat-dialog-actions>