export let subscriptionCodeToTitle: {[key: string]: string } = {
    "MW" : "Maintenance Window",
    "PM" : "Power Management",
    "SUS" : "Windows Updates",
    "SCUP" : "3rd-Party App Updates",
    "DCU" : "Dell/MS Drivers",
    "W10" : "Windows Feature Updates",
    "RBTNTF" : "Reboot Notification",
    "UPC" : "User Profile Cleanup",
    "ODKFM" : "OneDrive Options",
    "ADA" : "ADA Graphics"
  };