export class OrgStructure {
    [key: string]: OrgStructureDescriptor[];
    readonly ORG: OrgStructureDescriptor[] = [];
    readonly BLDG: OrgStructureDescriptor[] = [];
    readonly UNIT: OrgStructureDescriptor[] = [];
    readonly DEPT: OrgStructureDescriptor[] = [];

    constructor(_raw: OrgStructure | undefined) {
        if (_raw) {
            this.ORG = _raw.ORG.map(o => new OrgStructureDescriptor(o));
            this.BLDG = _raw.BLDG.map(o => new OrgStructureDescriptor(o));
            this.UNIT = _raw.UNIT.map(o => new OrgStructureDescriptor(o));
            this.DEPT = _raw.DEPT.map(o => new OrgStructureDescriptor(o));
        }
    }
}

export class OrgStructureDescriptor {
    constructor(raw: OrgStructureDescriptor) {
        this.name = raw.name;
        this.shortName = raw.shortName ?? this.shortName;
        this.description = raw.description;
        this.memberOf = raw.memberOf;
    }
    public shortName(i: number = 0): string | undefined {
        let prefix = new RegExp(`^(?:[^-]+-){${i+1}}`).exec(this.name ?? '');
        if (!prefix || prefix.length != 1) {
            return this.name;
        }
        return this.name?.substring(prefix[0].length);
    }
    public readonly name?: string;
    public readonly description?: string;
    public readonly memberOf?: string;

    public ui: { selected: boolean } = { selected: false };
}