import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommunityAuthService } from 'src/app/services/community-auth.service';

@Component({
  selector: 'app-branding-bar',
  templateUrl: './branding-bar.component.html',
  styleUrls: ['./branding-bar.component.scss'],  
  encapsulation: ViewEncapsulation.None
})
export class BrandingBarComponent implements OnInit {

  constructor(
    public authService: CommunityAuthService
  ) { }

  ngOnInit(): void {
  }

}
