import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, firstValueFrom, map } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { apiConfiguration } from 'src/environments/environment';
import { OrgGroup } from "../models/org-group";
import { OrgGroupFilter } from "../models/org-filter";

@Injectable()
export class OrgGroupResolver  {
    constructor(
        private router: Router,
        private location: Location,
        private http: HttpClient)
    {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrgGroup[] | void> {
        return this.request(route);
    }

    request(route: ActivatedRouteSnapshot): Observable<OrgGroup[] | void> {
        const p = route.params;
        const q = route.queryParams;
        try {
            if (p["OrgGroupName"] === "--selected--") {
                const filter = new OrgGroupFilter();
                filter.filterBy = 'Keys';
                filter.keywords = q['selected']?.split(',') ?? [];
                    
                return this.http.get<any[]>(apiConfiguration.apiBaseUrl + "orgstructure/filter?" + filter.serialize())
                    .pipe(map(results => results.map((cdp: any | OrgGroup) => new OrgGroup(cdp))));
            }

            const url = apiConfiguration.apiBaseUrl + `orgstructure/${p["OrgGroupName"]}`;
            return this.http.get<OrgGroup>(url).pipe(map(c => [new OrgGroup(c)]));
        } catch (err) {
            console.error(err);
            return from(this.notfound(`We cannot find this endpoint. This is likely an app configuration issue.`));
        }
    }

    
    async notfound(msg: string): Promise<OrgGroup[] | void> {
        const path = this.location.path();
        await this.router.navigate(['notfound'], { skipLocationChange: true, queryParams: { message: msg}});
        this.location.replaceState(path);
    }
}
