import { Component, Input, OnInit } from '@angular/core';
import { ActiveUser } from 'src/app/models/active-user';
import { CommunityAuthService } from 'src/app/services/community-auth.service';

@Component({
  selector: 'app-user-tool-bar',
  templateUrl: './user-tool-bar.component.html',
  styleUrls: ['./user-tool-bar.component.scss']
})
export class UserToolBarComponent implements OnInit {
  @Input() user?: ActiveUser;
  public loading: boolean = false;

  constructor(public authService: CommunityAuthService) {
  }

  ngOnInit(): void {
  }

}
