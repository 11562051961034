<app-user-tool-bar [user]="user"></app-user-tool-bar>

<section class="container">
    <div class="content-container">
        <h1 class="content-heading"><mat-icon style="transform: scale(2)">contact_support</mat-icon>&nbsp;&nbsp;Not Found</h1>
        <div class="content-body">
            <p>
                We can't find what you're requesting. If you believe this is in error, please contact the ITS Help Desk for further assistance.
            </p>
            <div *ngIf='message'>
                <h2>Details</h2>
                <code>
                    {{ message }}
                </code>
            </div>
        </div>  
    </div>
</section>