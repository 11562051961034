<mat-dialog-content>
    <h2>{{ title }}</h2>
    <div class="dialog-body">
        <h3>Other AD Fields</h3>
        <section>
            <table mat-table [dataSource]="fieldArray">
                <ng-container matColumnDef="fieldName">
                    <th mat-header-cell *matHeaderCellDef>Field Name</th>
                    <td mat-cell *matCellDef="let row">{{ row.fieldName }}</td>
                </ng-container>
                <ng-container matColumnDef="fieldValue">
                    <th mat-header-cell *matHeaderCellDef>Value</th>
                    <td mat-cell *matCellDef="let row">
                        <ng-container [ngSwitch]="row.fieldType">
                            <ng-container *ngSwitchCase="'date'">{{ row.fieldValue | date: 'EEEE, yyyy-MM-dd hh:mm:ss'
                                }}</ng-container>
                            <ng-container *ngSwitchDefault>{{ row.fieldValue }}</ng-container>
                        </ng-container>
                    </td>
                </ng-container>
                <!--<tr mat-header-row *matHeaderRowDef="['fieldName', 'fieldValue']; sticky: false"></tr>-->
                <tr mat-row *matRowDef="let row; columns: ['fieldName', 'fieldValue']"></tr>
            </table>
        </section>
        <!--<section [formGroup]='filterForm'>
            <mat-checkbox formControlName="showGroupFullDn">Show Group Full DN</mat-checkbox>
            <mat-checkbox formControlName="showOrgSubs" style="margin-left: 2em;">Show Org/Subscription Groups</mat-checkbox>
        </section>-->
        <section>
            <div class="subscriptions">
                <h3>Subscriptions</h3>
                <ul>
                    <li *ngFor="let group of subscriptions">{{ groupByName[group.name].key }}: {{ groupByName[group.name].description }}</li>
                </ul>
            </div>
            <div class="orgs">
                <h3>Org Structure</h3>
                <ul>
                    <li *ngFor="let group of orgs">{{ groupByName[group.name].key }}: {{ groupByName[group.name].description }}</li>
                </ul>
            </div>
        </section>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions">
    <button mat-raised-button (click)='cancel()'>Okay</button>
</mat-dialog-actions>