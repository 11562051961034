<div class="landing-container">
  <div class="landing-body">
    <div class="col-md-offset-2 col-md-4">
      <div class="panel panel-default">
          <div class="panel-heading" fxLayout="col" fxLayoutAlign="start center">
              <mat-icon>person</mat-icon> 
              <h4>You are now logged out. <a href='/'>Log back in.</a></h4>
          </div>
          <div class="panel-body" fxLayout="col" fxLayoutAlign="start center">
            Have a wonderful day!
          </div>
      </div>
    </div>
  </div>
  <footer class="main-footer-container">
      <div class="wrapper footer-top">
        <a href="https://uiowa.edu/" target="_blank" title="The University of Iowa">
          <img alt="The University of Iowa homepage" src="assets/images/block-iowa-gold02.png" class="uiowa-logo" />
        </a>
          <h2>ECM End User Support Tools</h2>
        <address>
          <p>
            2800 University Capitol Centre <br>
            Iowa City, Iowa 52242 <br>
            <a href="tel:319-384-4357">
              <mat-icon>phone</mat-icon>319-384-4357
            </a>
            <a href="mailto:its-helpdesk@uiowa.edu?subject=ECM End User Support (EUS) Tools Website">
              <mat-icon>email</mat-icon>its-helpdesk@uiowa.edu
            </a>
          </p>
        </address>
      </div>
      <div class="footer-bottom">
        <p class="footer-inline">
          &copy; {{ year }} <a href="https://uiowa.edu/" target="_blank" title="The University of Iowa homepage">The University of Iowa</a><span style="margin: 0 8px; line-height: 0;">&#124;</span>All rights reserved.<span style="margin: 0 8px; line-height: 0;">&#124;</span>Developed by <a href="https://its.uiowa.edu/app-dev-integration" target="_blank" title="ITS Enterprise Services">ITS Enterprise Services</a>
        </p>
      </div>  
  </footer>
</div>
