import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-misc',
  templateUrl: './misc.component.html',
  styleUrls: ['./misc.component.scss']
})
export class MiscErrorComponent implements OnInit {
  message?: string;
  
  constructor(
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(this.setMessage.bind(this));
  }

  setMessage(state: any) {
    this.message = state.message
        || "Sorry, we can't seem to find what you're requesting.";
  }

}
