<mat-dialog-content>
    <h2>{{ title }}</h2>
    <div class="dialog-body">
        <div *ngIf="isLoading; else done">
            <mat-spinner color="primary" diameters="300" class="spinner" >
            </mat-spinner>
        </div>
        <ng-template #done>
            <section *ngIf="syncedSoftware.length>0; else nothing">
            <h3>Software packages synced to {{computerName}}:</h3>
            <mat-table [dataSource]="syncedSoftware" class="mat-elevation-z8">
                <ng-container matColumnDef="description">
                  <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.description}} </mat-cell>
                </ng-container>              
                <ng-container matColumnDef="name">
                  <mat-header-cell *matHeaderCellDef> Deployment Type </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.name}} </mat-cell>
                </ng-container>
                <ng-container matColumnDef="deploymentSource">
                  <mat-header-cell *matHeaderCellDef> Deployment Source </mat-header-cell>
                  <mat-cell *matCellDef="let element"> {{element.deploymentSource}} </mat-cell>
                </ng-container>              
                <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
              </mat-table>
        </section>
        <ng-template #nothing>
            <h3>No software has been synced to {{computerName}}.</h3>
        </ng-template>
    </ng-template>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions">
    <button mat-raised-button (click)='cancel()'>{{ closeText }}</button>
</mat-dialog-actions>