<app-user-tool-bar [user]="user"></app-user-tool-bar>
<h1>Search for devices below</h1>
<div fxLayout="column" fxLayoutAlign="center start" fxLayout.gt-sm="row" fxLayoutAlign.gt-sm="space-between center"
    fxLayoutGap.gt-sm="1em" fxFill style="padding: 0 2em 0.5em 2em" (keydown)="handleFormKeydown($event)">
    <mat-form-field [formGroup]='filterForm' fxFill fxFlex.gt-sm="60%">
        <input type="text" placeholder='Search device name' matInput formControlName="name">
        <button *ngIf="filterForm.get('name')?.value" matSuffix mat-icon-button type="button" aria-label="Clear"
            (click)="filterForm.get('name')?.setValue(null); $event.stopPropagation()">
            <mat-icon>close</mat-icon>
        </button>
        <mat-button-toggle-group formControlName="nameOp" *ngIf="filterForm.get('name')?.value?.includes(' ')" matSuffix mat-icon-button aria-label="search by name">
            <mat-button-toggle value="and" matTooltip="interpret spaces as AND">And</mat-button-toggle>
            <mat-button-toggle value="or" matTooltip="interpret spaces as OR">Or</mat-button-toggle>
        </mat-button-toggle-group>
    </mat-form-field>
    <mat-form-field [formGroup]='filterForm' fxFill fxFlex.gt-sm="6em" fxFlex.gt-md="8em">
        <input type="text" placeholder='Organization' matInput formControlName="org" [matAutocomplete]="orgAuto">
        <mat-autocomplete autoActiveFirstOption #orgAuto="matAutocomplete" panelWidth="30em">
            <mat-option *ngFor="let o of filtered['org'] | async" [value]="o.shortName(0)" title="({{o.shortName(0)}}) {{o.description}}">
                {{o.description}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="filterForm.get('org')?.value" matSuffix mat-icon-button type="button" aria-label="Clear"
            (click)="filterForm.get('org')?.setValue(null); $event.stopPropagation()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field [formGroup]='filterForm' fxFill fxFlex.gt-sm="6em" fxFlex.gt-md="8em">
        <input type="text" placeholder='Department' matInput formControlName="dept" [matAutocomplete]="deptAuto">
        <mat-autocomplete autoActiveFirstOption #deptAuto="matAutocomplete" panelWidth="30em">
            <mat-option *ngFor="let o of filtered['dept'] | async" [value]="o.shortName(1)" title="({{o.shortName(1)}}) {{o.description}}">
                {{o.description}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="filterForm.get('dept')?.value" matSuffix mat-icon-button type="button" aria-label="Clear"
            (click)="filterForm.get('dept')?.setValue(null); $event.stopPropagation()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field [formGroup]='filterForm' fxFill fxFlex.gt-sm="6em" fxFlex.gt-md="8em">
        <input type="text" placeholder='Unit' matInput formControlName="unit" [matAutocomplete]="unitAuto">
        <mat-autocomplete autoActiveFirstOption #unitAuto="matAutocomplete" panelWidth="30em">
            <mat-option *ngFor="let o of filtered['unit'] | async" [value]="o.shortName(2)" title="({{o.shortName(2)}}) {{o.description}}">
                {{o.description}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="filterForm.get('unit')?.value" matSuffix mat-icon-button type="button" aria-label="Clear"
            (click)="filterForm.get('unit')?.setValue(null); $event.stopPropagation()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <mat-form-field [formGroup]='filterForm' fxFill fxFlex.gt-sm="6em" fxFlex.gt-md="8em">
        <input type="text" placeholder='Building' matInput formControlName="bldg" [matAutocomplete]="bldgAuto">
        <mat-autocomplete autoActiveFirstOption #bldgAuto="matAutocomplete" panelWidth="30em">
            <mat-option *ngFor="let o of filtered['bldg'] | async" [value]="o.shortName(0)" title="({{o.shortName(0)}}) {{o.description}}">
                {{o.description}}
            </mat-option>
        </mat-autocomplete>
        <button *ngIf="filterForm.get('bldg')?.value" matSuffix mat-icon-button type="button" aria-label="Clear"
            (click)="filterForm.get('bldg')?.setValue(null); $event.stopPropagation()">
            <mat-icon>close</mat-icon>
        </button>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="end center" fxFill fxFlex.gt-sm="13em" fxLayoutGap="1em">
        <button mat-raised-button color="default" (click)='filterForm.reset()' fxFlex="6em">
            Clear All
        </button>
        <button mat-raised-button color="primary" (click)='search()'  fxFlex="6em">
            Search
        </button>
    </div>
</div>
<div class="table-holder">
    <div *ngIf="isQuerying" class="querying-notif" fxFlexLayout="row" fxLayoutAlign="center center">
        <mat-progress-spinner mode="indeterminate" diameter="50">
        </mat-progress-spinner>
    </div>
    <mat-table class="table-computers" mat-table [dataSource]='computers' matSort matSortDirection="asc" matSortActive="name" multiTemplateDataRows>
        <div class="flex-container">
        <ng-container matColumnDef="selector">
            <mat-row>
                <mat-header-cell *matHeaderCellDef class="header-tools" colspan="7">
                    <ng-container *ngIf="computers?.length">
                        <mat-checkbox [(ngModel)]="allSelected" [indeterminate]="selectAllIndeterminate" (change)="toggleSelectAll()" #selectAll id="selectAll"></mat-checkbox>
                    </ng-container>
                    <ng-container *ngIf="anySelected">
                        <span style="padding-top: 0.25em;">
                            {{ selected.length }} selected
                        </span>
                        <button mat-button (click)="showEditManyOrgStructure(selected)" title="Edit organization structure">
                            <mat-icon>edit</mat-icon>
                        </button>
                        <button mat-button (click)="showEditManySubscriptions(selected)" title="Edit subscriptions">
                            <mat-icon>subscriptions</mat-icon>
                        </button>
                    </ng-container>
                </mat-header-cell>
            </mat-row>
            <mat-row>
                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                 <mat-cell class="selector-one" *matCellDef="let row" >
                    <mat-checkbox [(ngModel)]="row.ui.selected" (change)="adjustSelectAll()" ></mat-checkbox>
                </mat-cell>
            </mat-row>
        </ng-container>
        <ng-container matColumnDef="name">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Device Name</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{ row.name }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="location">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Location</mat-header-cell>
            <mat-cell *matCellDef="let row" [title]="row.location">
                {{ row.location }}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
            <mat-header-cell *matHeaderCellDef mat-sort-header>Description</mat-header-cell>
            <mat-cell *matCellDef="let row" [title]="row.description">
                {{row.description}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef>Actions</mat-header-cell>
            <mat-cell *matCellDef="let row">
                <button mat-button [matMenuTriggerFor]="tools" *ngIf="0 != 0">
                    <mat-icon>menu</mat-icon>
                </button>
                <mat-menu #tools>
                    <button mat-menu-item (click)="showMoreInfo(row)">
                        <mat-icon>info</mat-icon>Show Details
                    </button>
                    <button mat-menu-item>
                        <mat-icon>edit</mat-icon>Edit Org Structure
                    </button>
                    <button mat-menu-item>
                        <mat-icon>subscriptions</mat-icon>Edit Subscriptions
                    </button>
                    <button mat-menu-item>
                        <mat-icon>extension</mat-icon>Deploy software to computer
                    </button>                    
                </mat-menu>
                <button mat-button (click)="showMoreInfo([row])" title="Show details">
                    <mat-icon>info</mat-icon>
                </button>
                <button mat-button (click)="showEditOrgStructure([row])" title="Edit organization structure">
                    <mat-icon>edit</mat-icon>
                </button>
                <button mat-button (click)="showEditSubscriptions([row])" title="Edit subscriptions">
                    <mat-icon>subscriptions</mat-icon>
                </button>
                <button mat-button (click)="showDeploySoftwareToComputer([row])" title="Deploy software to computer">
                    <mat-icon>extension</mat-icon>
                </button>
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="created">
            <mat-header-cell *matHeaderCellDef>Created</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.created | date: 'yyyy-MM-dd'}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef="lastupdated">
            <mat-header-cell *matHeaderCellDef>Last Updated</mat-header-cell>
            <mat-cell *matCellDef="let row">
                {{row.lastupdated | date: 'yyyy-MM-dd'}}
            </mat-cell>
        </ng-container>
        <ng-container matColumnDef='footer-pagination'>
            <mat-footer-cell *matFooterCellDef class="footer" colspan="7">
                <section fxLayout="row">
                    <span fxFlex="15"></span>
                    <mat-paginator fxFlex="70" fxLayoutAlign="center center" [pageSizeOptions]="[5, 10, 20, 50, 100, 250, 500]"
                        [pageSize]="10">
                    </mat-paginator>
                    <div fxFlex style="text-align: right; margin-right: 4em;">
                        <button mat-button (click)="scrollTop()" *ngIf="! isScrolledTop">
                            <mat-icon>arrow_upward</mat-icon> Scroll to search form
                        </button>
                    </div>
                </section>
            </mat-footer-cell>
        </ng-container>
        <ng-container matColumnDef="empty">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row"></mat-cell>
        </ng-container>
        <mat-header-row class="table-header header-tools" *matHeaderRowDef="['selector']; sticky: true"></mat-header-row>
        <mat-header-row class="table-header" 
            *matHeaderRowDef="['empty', 'name', 'location', 'description', 'created', 'lastupdated', 'actions']; sticky: true">
        </mat-header-row>
        <mat-row class="table-row" 
            *matRowDef="let row; let i = dataIndex; columns: ['selector', 'name', 'location', 'description', 'created', 'lastupdated', 'actions'];">
        </mat-row>
        <ng-container *matNoDataRow>
            <div class="no-data">
                <h3>No results</h3>
                <p>Please enter a query and click search.
                </p>
            </div>                
        </ng-container>
        <mat-footer-row *matFooterRowDef="['footer-pagination']; sticky: true" ></mat-footer-row>
        </div>
    </mat-table>
</div>
