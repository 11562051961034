import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacySnackBarRef as MatSnackBarRef, MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA } from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-error-snack',
  templateUrl: './error-snack.component.html',
  styleUrls: ['./error-snack.component.scss']
})
export class ErrorSnackComponent implements OnInit {
  message: any;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, 
   private ref:MatSnackBarRef<ErrorSnackComponent>) { this.message = data.message };

  ngOnInit(): void {
  }

  close(){
    this.ref.dismiss();
  }
}
