<mat-dialog-content>
    <h2>Edit Subscriptions</h2>
    <div class="dialog-body">
        <section>
            <h3>Machine<span *ngIf="computers.length > 1">s</span> Info</h3>
            <div>
                <span>{{ computersDescription }}</span>
            </div>
        </section>
        <h3>Subscriptions</h3>
        <section [formGroup]="form" fxLayout="column">
            <mat-form-field *ngFor="let item of codeToTitle | keyvalue">
                <mat-select formControlName="{{item.key}}" panelWidth="25em"
                                        [placeholder]="item.value">
                    <mat-option *ngFor="let o of options[item.key]" [value]="o.name" title="{{o.description}} ({{o.name}})" [class]="{ 'is-default': o.isDefault, 'is-recommended': o.isRecommended }">
                        {{ o.description }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </section>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)='reset()'>Reset Form</button>
    <button mat-raised-button (click)='dismiss()'>Dismiss</button>
    <button mat-raised-button (click)='save()' *ngIf="user.hasAnyRole('CanWrite')" [disabled]="! form.dirty">Update</button>
</mat-dialog-actions>