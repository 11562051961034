import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { firstValueFrom, Observable } from 'rxjs';
import { ActiveUser } from 'src/app/models/active-user';
import { Computer } from 'src/app/models/computer';
import { OrgStructure } from 'src/app/models/org-structure';
import { SubscriptionDescriptor } from 'src/app/models/subscription';
import { subscriptionCodeToTitle } from 'src/app/models/subscription-codes';
import { MemberOfUtilityService } from 'src/app/services/memberof-utility.service';
import { apiConfiguration } from 'src/environments/environment';
import { ConfirmChangesComponent } from '../confirm-changes/confirm-changes.component';

@Component({
  selector: 'app-edit-subscriptions',
  templateUrl: './edit-subscriptions.component.html',
  styleUrls: ['./edit-subscriptions.component.scss']
})
export class EditSubscriptionsComponent implements OnInit {
  computers!: Computer[];
  computersDescription!: string;
  form!: FormGroup;
  options!: { [key: string]: SubscriptionDescriptor[] };
  readonly codeToTitle = subscriptionCodeToTitle;
  user: ActiveUser;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { computers: Computer[], options: { [key: string]: SubscriptionDescriptor[] }, user: ActiveUser },
    private _http: HttpClient,
    private _dialogRef: MatDialogRef<EditSubscriptionsComponent>,
    private _dialog: MatDialog,
    private _memberOfUtil: MemberOfUtilityService,
    fb: FormBuilder
  ) {
    this.user = _data.user;
    this.options = _data.options;
    this.computers = _data.computers;
    this.computersDescription = this.computers.map(c => c.name).join(", ");
    this.options['ADA'] = [
      new SubscriptionDescriptor({ description: 'Uses ADA Graphics', isDefault: false, isRecommended: false, name: this.options['GPO'][0].name, distinguishedName: this.options['GPO'][0].distinguishedName, ui: { selected: false } }),
      new SubscriptionDescriptor({ description: 'Regular Graphics', isDefault: true, isRecommended: false, name: '', ui: { selected: false } }),
    ];
    if (this.computers.length > 1) {
      for (let key in this.options) {
        if (!this.options[key].find(o => o.name === 'various'))
          this.options[key].push(new SubscriptionDescriptor({ description: "--Various--", isDefault: false, isRecommended: false, name: 'various', ui: { selected: false } }));
      }
    }

    type keysType = typeof this.codeToTitle;
    type formDefDictType = { [key: keyof keysType]: any[] };
    const formDef = Object
      .keys(this.codeToTitle)
      .reduce((p, c) => { p[c] = [this.getCurrentAsDefault(c), { nonNullable: true }]; console.log(c); return p; }, <formDefDictType>{});
    formDef['ADA'] = [this.getCurrentAsDefault('ADA'), { nonNullable: true }];
    this.form = fb.group(formDef);
  }

  ngOnInit(): void {
  }

  getCurrentAsDefault(key: string) {
    const def = this._memberOfUtil.getComputerDefaultForKey(this.computers[0], key);
    const isHomogenous = this.computers.reduce((isHom, comp) => isHom && def == this._memberOfUtil.getComputerDefaultForKey(comp, key), true);
    if (!isHomogenous && this.computers.length > 1) {
      return "various";
    }
    const results = /^CN=(.+?),/.exec(def);
    if (!results) {
      return "";
    }
    return results[1];
  }

  setSelection(which: string, change: MatSelectChange) {
    for (let op of this._data.options[which.toUpperCase()]) {
      op.ui.selected = op.name === change.value;
    }
    this.form.get(which)?.setValue('')
  }

  dismiss(result?: any) {
    this._dialogRef.close(result);
  }

  confirm() {

  }

  reset() { this.form.reset(); }

  async save() {
    type stringDict = { [key: string]: string };
    type stringArrayDict = {[key: string]: (string | undefined)[] };
    const proposedMemberships = Object
      .keys(this.codeToTitle)
      .reduce(
        (dict: stringDict, gtype) => { dict[gtype] = this.form.get(gtype)?.value; return dict; },
        <stringDict>{});
    const availableGroups = Object
      .keys(this.codeToTitle)
      .reduce(
        (dict: stringArrayDict, gtype) => { dict[gtype] = this.options[gtype].map(o => o.distinguishedName); return dict; },
        <stringArrayDict>{}
      );
    const deltas = this._memberOfUtil.generateSubscriptionDeltas(this.computers, proposedMemberships, availableGroups);
    const localUpdates = this.computers.map(c => { return { ...c }; });
    const updated = await firstValueFrom(this._dialog.open(ConfirmChangesComponent, { data: { deltas, localUpdates } }).afterClosed());
    if (updated?.length > 0)
      this.dismiss(updated);
  }

  /*async saveOld() {
    const model = {
      name: this.computers[0].name,
      description: this.form.get('description')?.value,
      location: this.form.get('location')?.value
    };
    const localUpdate = {
      ...this.computers[0],
      ...model
    };

    const resultsPromises = Object.keys(this.codeToTitle)
      .map(gtype => {
        const current = this._memberOfUtil.getComputerDefaultForKey(this.computers[0], gtype);
        const next = this.form.get(`${gtype}`)?.value;
        const nextFull = this.options[gtype].find(o => o.name === next)?.distinguishedName;
        //const nextFull = next;
        console.log(gtype, nextFull, current, this.options[gtype], next);
        if (nextFull == current) return null // no change;
        if (gtype === "ADA" && current === '' && nextFull === undefined) return // ADA unchanged
        return {
          memberDn: this.computers[0].name,
          action: this.actionCodeLookup[current == '' ? 'Add' : (next == '' ? 'Remove' : 'Replace')],
          groupToRemove: current,
          groupToAdd: nextFull
        };
      })
      .filter(r => r != null)
      .map(async (r) => {
        console.log(r);
        const result = await firstValueFrom(this._http.put(apiConfiguration.apiBaseUrl + "Computer/change-group", r));
        this.update(r, <any>localUpdate);
        return result;
      });
    const results = await Promise.all(resultsPromises);
    console.log(results);
    this.dismiss();    
  }*/

}
