<mat-dialog-content>
    <h2>{{ title }}</h2>
    <div class="dialog-body">
        <section [formGroup]="form" fxLayout="column" *ngIf="computers?.length == 1; else manyComputers">
            <mat-form-field fxFill>
                <input type="text" placeholder='Device description' matInput formControlName="description" fxFlex>
                <button mat-button title="Bake me a description cake" (click)="generateDescription()"
                    *ngIf="showSetDesc">
                    <mat-icon>cake</mat-icon>
                </button>
            </mat-form-field>
            <mat-form-field fxFill>
                <input type="text" placeholder="Device location" matInput formControlName="location">
            </mat-form-field>
        </section>
        <ng-template #manyComputers>
            <section>
                <h3>Machines</h3>
                <div>
                    <span>{{ computersDescription }}</span>
                </div>
            </section>
        </ng-template>
        <h3>Computer Group Memberships</h3>
        <section [formGroup]="form" fxLayout="column">
            <mat-form-field>
                <mat-select formControlName="bldgSelect" panelWidth="25em" (blur)="bldgfilter.value = ''"
                    (selectionChange)="setSelection('bldg', $event)" placeholder="Building" (closed)="form.get('bldg')?.setValue('')">
                    <mat-form-field fxFill>
                        <input type="text" matInput placeholder="filter..." formControlName="bldg" #bldgfilter>
                    </mat-form-field>
                    <mat-option *ngFor="let o of filtered['bldg'] | async" [value]="o.name">
                        {{o.shortName(0)}} {{o.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-select formControlName="orgSelect" panelWidth="25em" placeholder="Organization"
                    (selectionChange)="setSelection('org', $event)" (closed)="form.get('org')?.setValue('')">
                    <mat-form-field fxFill>
                        <input type="text" matInput placeholder="filter..." formControlName="org">
                    </mat-form-field>
                    <mat-option *ngFor="let o of filtered['org'] | async" [value]="o.name">
                        {{o.shortName(0)}} {{o.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-select formControlName="deptSelect" panelWidth="25em" placeholder="Department"
                    (selectionChange)="setSelection('dept', $event)" (closed)="form.get('dept')?.setValue('')">
                    <mat-form-field fxFill>
                        <input type="text" matInput placeholder="filter..." formControlName="dept">
                    </mat-form-field>
                    <mat-option *ngFor="let o of filtered['dept'] | async" [value]="o.name">
                        {{o.shortName(1)}} {{o.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-select formControlName="unitSelect" panelWidth="25em" placeholder="Unit"
                    (selectionChange)="setSelection('unit', $event)" (closed)="form.get('unit')?.setValue('')">
                    <mat-form-field fxFill>
                        <input type="text" matInput placeholder="filter..." formControlName="unit">
                    </mat-form-field>
                    <!-- <mat-option>None</mat-option> -->
                    <mat-option *ngFor="let o of filtered['unit'] | async" [value]="o.name">
                        {{o.shortName(2)}} {{o.description}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </section>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button (click)='reset()'>Reset Form</button>
    <button mat-raised-button (click)='dismiss()'>Dismiss</button>
    <button mat-raised-button (click)='save()' *ngIf="user.hasAnyRole('CanWrite')" [disabled]="! form.dirty">Update</button>
</mat-dialog-actions>