import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatLegacyCheckboxChange as MatCheckboxChange } from '@angular/material/legacy-checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { ActivatedRoute, Data } from '@angular/router';
import { debounceTime, firstValueFrom, forkJoin, map, merge, Observable, startWith, Subscriber, switchMap, tap } from 'rxjs';
import { ActiveUser } from 'src/app/models/active-user';
import { User } from 'src/app/models/user';
import { UserFilterModel, UserFilterType } from 'src/app/models/user-filter';
import { apiConfiguration } from 'src/environments/environment';
import { ConfirmComponent } from '../dialog-bodies/confirm/confirm.component';
import { Location } from '@angular/common';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  //@ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  public user?: ActiveUser;
  public registeredUsers: Array<User> = new Array<User>();
  public filter!: UserFilterModel;
  form: FormGroup;
  public loading: boolean = false;
  private _saveChanges!: Observable<boolean>;
  private _saveChangesEmitter!: Subscriber<boolean>;
  
  constructor(
    public location: Location,
    private _route: ActivatedRoute, private _fb: FormBuilder, private _http: HttpClient,
    private _snackBar: MatSnackBar, private _dialog: MatDialog)
  {
    this.form = this._fb.group({ keywords: [''], hawkId: [''], isNewAdmin: [false] });
    this._route.data.subscribe(this.userChanges.bind(this));
    this._saveChanges = new Observable(subscriber => this._saveChangesEmitter = subscriber);
  }

  private userChanges({user}:{user:ActiveUser}|Data) {
    this.user = user;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.filter = new UserFilterModel(UserFilterType.Keywords);
    this.setSort(this.filter, { direction: this.sort.direction, active: this.sort.active });
    this.sort.sortChange.pipe(tap(this.setSort.bind(this, this.filter))).subscribe();
    //this.paginator.page.pipe(tap(this.setPage.bind(this, this.filter))).subscribe();
    merge(
      this.form.valueChanges.pipe(tap(this.setFilter.bind(this, this.filter))),
      this.sort.sortChange.pipe(tap(this.setSort.bind(this, this.filter))),
      this._saveChanges
      //this.paginator.page.pipe(tap(this.setPage.bind(this, this.filter)))
    ).pipe(
      startWith(this.filter),
      debounceTime(300),
      tap(_ => this.loading = true),
      switchMap(() => forkJoin([
        this._http.get<number>(apiConfiguration.apiBaseUrl + "user/count?" + this.filter.serialize()),
        this._http.get<any[]>(apiConfiguration.apiBaseUrl + "user/search?" + this.filter.serialize()).pipe(map(users => users.map(u => new User(u.id, u.hawkId, u.userRoles.filter((ur: any) => ur.role.roleName == "Admin").length > 0))))
      ]))).subscribe(([count, users]: [number, User[]]) => {
        this.loading = false;
        //this.paginator.length = count;
        this.registeredUsers = users;
      });
  }

  private setFilter(filter: UserFilterModel, v: { [ctrl: string]: string }) {
    filter.keywords = v["keywords"].split(' ');
  }

  private setSort(filter: UserFilterModel, v: Sort) {
    if (v.direction === "") {
      filter.sortSpecifications = [];
      return;
    }

    filter.sortSpecifications = [
      { Property: v.active, Direction: v.direction == "asc" ? "Ascending" : "Descending" }
    ];
  }

  private async setPage(filter: UserFilterModel) {
    //filter.page = this.paginator.pageIndex;
    //filter.pageSize = this.paginator.pageSize;
  }

  public async toggleAdmin(row: User, ev: MatCheckboxChange) {
    row.isAdmin = ev.checked;
    try {
      await firstValueFrom(this._http.put(apiConfiguration.apiBaseUrl + "user", row));
      this._saveChangesEmitter.next(true);
      this._snackBar.open("Successfully updated user.", undefined, { duration: 4000 });
    } catch (err) {
      console.error(err);
      this._snackBar.open("Failed. See console for more information.", undefined, { duration: 4000 });
    }
  }

  public async addHawkId(hawkId: string, isAdmin: boolean) {
    const newUser = { hawkId, isAdmin };
    console.log("would create ", newUser);
    try {
      await firstValueFrom(this._http.post(apiConfiguration.apiBaseUrl + "user", newUser));
      this._saveChangesEmitter.next(true);
      this._snackBar.open("Success!", undefined, { duration: 4000 });
    } catch (err) {
      console.error(err);
      this._snackBar.open("Failed. See console for more information.", undefined, { duration: 4000 });
    }
  }

  public async removeUser(row: User) {
    try {
      const confirmed = await firstValueFrom(this._dialog.open(ConfirmComponent, { data: {
        title: 'Remove ' + row.hawkId + '?',
        message: 'Are you sure you want to remove this user? They will still be able to log in but will be unable to make changes.'
      }}).afterClosed());
      if (confirmed) {
        await firstValueFrom(this._http.delete(apiConfiguration.apiBaseUrl + `user?id=${row.id}`));
        this._saveChangesEmitter.next(true);
        this._snackBar.open("Successfully removed user.", undefined, { duration: 4000 });
      } else {
        console.log("User canceled deletion.");
      }
    } catch (err) {
      console.error(err);
      this._snackBar.open("Failed. See console for more information.", undefined, { duration: 4000 });
    }
  }
}
