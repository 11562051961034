<mat-dialog-content>
    <h2>{{ title }}</h2>
    <div class="dialog-body">
        <section *ngIf="software !== undefined && computerId!== undefined">
            <div>Deployment Type: {{deployType.label}}</div>
            <h3>Software to be deployed to {{computerId}}:</h3>
            <ul>
                <li *ngFor="let sw of software">
                    {{ sw.description }}
                </li>
            </ul>
            <div *ngIf="software.length==0">Nothing in the list. All synced software in {{deployType.type}} type will be cleaned.</div>
            <div *ngIf="deployType.type=='APD-*-MAN'" class="warning">Warning: Mandatory Deployments Selected</div>
        </section>
        <div *ngIf="isWritingADGroup">
            <mat-spinner color="primary" diameters="300" class="spinner" >
            </mat-spinner>
        </div>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end" class="dialog-actions">
    <button mat-raised-button (click)='cancel()'>{{ closeText }}</button>
    <button mat-raised-button (click)='applyChanges()' *ngIf="!allApplied">Apply Changes</button>
</mat-dialog-actions>