import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { from, Observable, of } from 'rxjs';
import { filter, switchMap, tap } from 'rxjs/operators';
import { OAuthConfig } from 'src/environments/environment';
import { CommunityAuthService } from '../services/community-auth.service';

//([a-ln-zA-LN-Z].*|m[b-zB-Z].*|ma[a-rT-ZA-RT-Z].*|mas[a-su-zA-SU-Z].*|mast[a-df-zA-DF-Z].*|maste[a-qs-zA-QS-Z].*)
@Injectable()
export class AuthGuard  {
    constructor(
        private oauthService: OAuthService,
        private authService: CommunityAuthService
    ) {}

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
      ): Observable<boolean> {
        this.oauthService.configure(OAuthConfig);
        //this.oauthService.events.subscribe(e => console.log("event, e", e));
        return from(this.authService.runInitialLoginSequence())
            .pipe(
                switchMap(_ => this.authService.isDoneLoading$),
                filter(isDone => isDone),
                switchMap(_ => this.authService.isAuthenticated$),
                tap(isAuth => { if (! isAuth) this.authService.login(state.url); })
            );
      }
}
