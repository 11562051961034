import { Computer } from "../models/computer";
import { SubscriptionDelta, actionCodeLookup } from "../models/subscription-delta";

export class MemberOfUtilityService {

    public generateSubscriptionDeltas(computers: Computer[], proposedMemberships: { [key: string]: string }, availableGroups: {[key: string]: (string | undefined)[] }) {
        //const deltas: { [key: string]: { localUpdate: Computer, deltas: SubscriptionDelta[] } } = {};
        let deltas: SubscriptionDelta[] = [];
        for (let i = 0; i < computers.length; i++) {
            const computer = computers[i];
            let localUpdate: Computer | any;
            localUpdate = { ...computers[i] }
            deltas = deltas.concat(this.generateGroupDeltasForComputer(computer, proposedMemberships, availableGroups));
        }
        return deltas;
    }

    getComputerDefaultForKey(computer: Computer, key: string) {
        let def: string;
        switch (key) {
          case "ADA": 
            def = computer.memberOf.find(m => m.toLowerCase().startsWith(`CN=GPO-Lockscreen-ADA`.toLowerCase())) ?? '';
            break;
          default:
            def = computer.memberOf.find(m => m.toLowerCase().startsWith(`CN=${key}`.toLowerCase())) ?? '';
        }
        return def;
      }

    private shortToLong(availableGroups: (string | undefined)[], short: string) {
        const result = short ? availableGroups.find(g => g?.toLowerCase().startsWith(`CN=${short},`.toLowerCase())) : '';
        // console.log("shortToLong", short, availableGroups);
        return result;
    }

    private generateGroupDeltasForComputer(computer: Computer, proposedMemberships: { [key: string]: string }, availableGroups: {[key: string]: (string | undefined)[] }) {
        return Object.keys(proposedMemberships)
            .map(this.getExistingAndProposedMembership.bind(this, computer, proposedMemberships, availableGroups))
            .filter(({ existing, proposed }) => existing !== proposed)
            // special case for ADA unchanged:
            .filter(({ groupType, existing, proposed }) => !(groupType === "ADA" && existing === '' && proposed === undefined))
            .filter(({ proposedShort }) => proposedShort !== "various")
            .map(this.generateDelta.bind(this, computer));
    }

    private getExistingAndProposedMembership(computer: Computer, proposedMemberships: { [key: string]: string }, availableGroups: {[key: string]: (string | undefined)[] }, gtype: string) {
        //const proposedShort = this.form.get(`${gtype}Select`)?.value;
        // console.log(proposedMemberships, gtype);
        const proposedShort = proposedMemberships[gtype.toUpperCase()];
        return {
            groupType: gtype,
            existing: this.getComputerDefaultForKey(computer, gtype),
            proposedShort,
            proposed: this.shortToLong(availableGroups[gtype], proposedShort)
        };
    }

    private generateDelta(computer: Computer, proposedChange: { groupType: string, existing: string, proposed: string | undefined, proposedShort: string }): SubscriptionDelta {
        // if(proposedChange.proposed == 'none')
        const { groupType, existing, proposed, proposedShort } = proposedChange;
        // console.log('delta', proposedChange);
        return new SubscriptionDelta(
            groupType,
            computer.name,
            actionCodeLookup[existing == '' ? 'Add' : (proposedShort == '' ? 'Remove' : 'Replace')],
            existing,
            proposed ?? ''
        );
    }
}