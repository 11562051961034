import { AbstractControl, FormGroup } from "@angular/forms";
import { Observable, throwError, map, startWith, merge } from "rxjs";
import { OrgStructure, OrgStructureDescriptor } from "../models/org-structure";

export class OrgStructurePipesFactory {
  public build(filterForm: FormGroup, opts: OrgStructure): { [key: string]: Observable<OrgStructureDescriptor[]> } {
    const filtered: { [key: string]: Observable<OrgStructureDescriptor[]> } = {};
    const vc = (c: string) => filterForm.get(c)?.valueChanges ?? throwError(() => new Error(`${c} does not exist.`));
    const conv = (c: string) => vc(c).pipe(map(_ => filterForm.get('org')?.value));
    filtered['bldg'] = vc('bldg').pipe(startWith(''), map(v => this.filterOrgStruct(opts, 'BLDG', v)));
    filtered['org'] = vc('org').pipe(startWith(''), map(v => this.filterOrgStruct(opts, 'ORG', v)));
    filtered['dept'] = merge(vc('dept'), conv('org')).pipe(startWith(''), map(v => this.filterOrgStruct(opts, 'DEPT', v, true)));
    filtered['unit'] = merge(vc('unit'), conv('org'), conv('dept')).pipe(startWith(''), map(v => this.filterOrgStruct(opts, 'UNIT', v, true)));
    return filtered;
  }

  private filterOrgStruct(opts: OrgStructure, which: string, curValue?: string, reset: boolean = false): OrgStructureDescriptor[] {
    let v = curValue?.toLowerCase() ?? '';
    if (reset) {
      opts[which].map(o => o.ui.selected = false);
    }
    return opts[which]
      .filter(o => o.name?.toLowerCase().includes(v) || o.description?.toLowerCase().includes(v));
  }
}