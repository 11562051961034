import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OAuthConfig } from 'src/environments/environment';
import { ComputerChooserComponent } from './components/computer-chooser/computer-chooser.component';
import { MiscErrorComponent } from './components/errors/misc/misc.component';
import { PageNotFoundComponent } from './components/errors/page-not-found/page-not-found.component';
import { HelpComponent } from './components/help/help.component';
import { LoggedOutComponent } from './components/logged-out/logged-out.component';
import { SettingsComponent } from './components/settings/settings.component';
import { AuthGuard } from './guards/auth.guard';
import { ComputerResolver } from './resolvers/computer.resolver';
import { ComputersResolver } from './resolvers/computers.resolver';
import { OrgStructureResolver } from './resolvers/org-structure.resolver';
import { SubscriptionResolver } from './resolvers/subscription.resolver';
import { UserWithFallbackResolver } from './resolvers/user-with-fallback.resolver';
import { UserResolver } from './resolvers/user.resolver';
import { OrgGroupChooserComponent } from './components/org-chooser/org-chooser.component';
import { OrgGroupResolver } from './resolvers/org-group.resolver';
import { OrgGroupsResolver } from './resolvers/org-groups.resolver';

const routes: Routes = [
  { path: 'should-login', redirectTo: OAuthConfig.loginUrl },
  { path: 'logged-out', component: LoggedOutComponent },
  {
    path: 'help',
    children: [
      { path: '', component: HelpComponent },
      { path: 'unknown-error', component: MiscErrorComponent },
      { path: 'not-found', component: PageNotFoundComponent },
    ]
  },
  {
    path: 'computers',
    resolve: { orgStructureOptions: OrgStructureResolver, results: ComputersResolver, subscriptionOptions: SubscriptionResolver, user: UserResolver },
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: ':computerName',
        resolve: { computers: ComputerResolver },
        children: [
          { path: 'org-structure', component: ComputerChooserComponent, data: { dialog: 'edit-org-structure' } },
          { path: 'subscriptions', component: ComputerChooserComponent, data: { dialog: 'edit-subscriptions' } },
          { path: 'details', component: ComputerChooserComponent, data: { dialog: 'show-more-info' } },
        ]
      },
      { path: '', canActivate: [AuthGuard], component: ComputerChooserComponent }
    ]
  },
  {
    path: 'orgs',
    resolve: { orgStructureOptions: OrgStructureResolver, results: OrgGroupsResolver, subscriptionOptions: SubscriptionResolver, user: UserResolver },
    canActivate: [AuthGuard],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: ':org',
        resolve: { orgs: OrgGroupResolver },
        children: [
          { path: 'org-structure', component: OrgGroupChooserComponent, data: { dialog: 'edit-org-structure' } },
          { path: 'subscriptions', component: OrgGroupChooserComponent, data: { dialog: 'edit-subscriptions' } },
          { path: 'details', component: OrgGroupChooserComponent, data: { dialog: 'show-more-info' } },
        ]
      },
      { path: '', canActivate: [AuthGuard], component: OrgGroupChooserComponent }
    ]
  },
  { path: '', redirectTo: 'computers', pathMatch: "full" },
  { path: '**', component: PageNotFoundComponent, resolve: { user: UserWithFallbackResolver } }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
