<mat-toolbar class="title-bar-container" fxLayout="row">
  <div class="title-bar">
      <nav *ngIf="user" class="main-navigation" label="main navigation" fxFlex mat-tab-nav-bar color="theme.palette.primary.contrastText">
        <div fxHide.xs fxHide.sm fxHide.md>
          <h2 class="offscreen">Main Navigation</h2>
          <a mat-tab-link [routerLink]="rlac.isActive ? ['/settings', 'courses'] : null" routerLinkActive #rlac="routerLinkActive">
            Course Settings</a>
          <a mat-tab-link [routerLink]="rlar.isActive ? ['/settings', 'registrar'] : null" routerLinkActive #rlar="routerLinkActive">
            Registrar Dates</a>
          <a mat-tab-link [routerLink]="rlae.isActive ? ['/settings', 'exports'] : null" routerLinkActive #rlae="routerLinkActive">
            Exports</a>
          <a mat-tab-link *ngIf="user.isAdmin" [routerLink]="rlaa.isActive ? ['/settings', 'admin'] : null" routerLinkActive #rlaa="routerLinkActive">
            Admin</a >
        </div>
      </nav>
    
    <div *ngIf="user" class="title-bar-right" fxLayout="row" fxLayoutAlign="space-between center">
      <p>Welcome, 
        <span *ngIf="!user.isImpersonated; else impersonated" class='e2e-logged-in-user'>{{ user.hawkId }}!</span>    
        <ng-template #impersonated>
          {{ user.hawkId }}
          <button mat-icon-button (click)='endImpersonation()' title='End Impersonation'>
            <mat-icon>exit_to_app</mat-icon>
          </button>
          <!-- {{ user.hawkId }} (<a (click)="endImpersonation()">end impersonation</a>). -->
        </ng-template>
      </p>
      <button fxHide.xs fxHide.sm fxHide.md mat-raised-button class="ml-btn" color="accent" (click)="authService.logout()">
        Logout
      </button>

      <button fxHide.gt-md mat-flat-button class="ml-btn" color="accent" [matMenuTriggerFor]="menu" aria-label="main-navigation">
        <mat-icon>menu</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <a mat-menu-item [routerLink]="['/settings', 'courses']">   
          <mat-icon>settings</mat-icon>         
          Course Settings
        </a>
        <a mat-menu-item [routerLink]="['/settings', 'registrar']">
          <mat-icon>event</mat-icon>
          Registrar Dates
        </a>
        <a mat-menu-item [routerLink]="['/settings', 'exports']">
          <mat-icon>import_export</mat-icon>
          Exports
        </a>
        <a mat-menu-item *ngIf="user.isAdmin" [routerLink]="['/settings', 'admin']">
          <mat-icon>admin_panel_settings</mat-icon>
          Admin
        </a>
        <hr />
        <a mat-menu-item (click)="authService.logout()">
          <mat-icon>arrow_back</mat-icon>
          Logout
        </a>
      </mat-menu>
    </div>
  </div>
</mat-toolbar>
