import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ActiveUser } from '../models/active-user';
import { apiConfiguration } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class RoleGuard  {

    constructor(
        private router: Router,
        private http: HttpClient
    ) { }

    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const userEndpoint = `${apiConfiguration.apiBaseUrl}user/current`;
        const user = new ActiveUser(await firstValueFrom(this.http.get<ActiveUser[]>(userEndpoint)));        
        if (user.hasAnyRole("Admin", "Impersonator"))
            return true;

        this.router.navigate(['/']);
        return false;
    }
}
