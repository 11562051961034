import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { map, filter } from 'rxjs/operators';
import { ActiveUser } from 'src/app/models/active-user';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnInit {
  message?: string;
  user?: ActiveUser;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router
  ) {
    this._route.data.subscribe(this.userChanges.bind(this));
  }

  private userChanges({user}:{user:ActiveUser}|Data) {
    this.user = user;
  }

  ngOnInit() {
    this._route.queryParams.subscribe(this.setMessage.bind(this));
  }

  async setMessage(state: any) {
    if (!state.message) {
      const finalUrl = this._route.toString();
      this.message = finalUrl ?? "Sorry, we can't seem to find what you're requesting.";
      return;
    }
    this.message = state.message;        
  }

}
