export class Software {        
    name: string ='';
    description : string ='';
    deploymentSource : string ='';

    constructor(raw: any){
        this.description = raw.description;
        this.name = raw.name;
        this.deploymentSource = raw.deploymentSource;
    }
}

export class AdSoftware {
    softwareAll : Software[] =[];
    softwareSynced : Software[] =[];
}

export class SyncedSoftwareSummary {
    available : Software[]=[];
    mandatory : Software[]=[];
    exclude : Software[]=[];
    asap : Software[]=[];
}

export class SoftwareDeployType {
    type : string = "";
    label : string = "";
}