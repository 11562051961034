<mat-dialog-content>
    <h2>{{ title }}</h2>
    <div class="dialog-body">
        <section *ngIf="model !== undefined && original !== undefined && (model.description != original.description || model.location != original.location)">
            <h3>
                <mat-icon class="problem-saving" *ngIf="modelUpdateError" [title]="modelUpdateError" (click)="copyToClipboard(modelUpdateError)">warning</mat-icon>
                Change fields</h3>
            <ul>
                <li *ngIf="model.description != original.description">Set field <b>[description]</b> = "{{ model.description }}"</li>
                <li *ngIf="model.location != original.location">Set field <b>[location]</b> = "{{ model.location }}"</li>
            </ul>
        </section>
        <section *ngIf="deltas.length > 0" style="width: min-content;">
            <h3>Change memberships</h3>
            <table mat-table [dataSource]="deltas" style="width: 100%;">
                <ng-container matColumnDef="result">
                    <th mat-header-cell *matHeaderCellDef>Applied?</th>
                    <td mat-cell *matCellDef="let row">
                        <mat-checkbox disabled [checked]="row?.ui?.applied"></mat-checkbox>
                        <mat-icon class="problem-saving" *ngIf="row?.ui?.lastError" [title]="row?.ui?.lastError" (click)="copyToClipboard(row?.ui?.lastError)">warning</mat-icon>
                        <!--<button mat-button *ngIf="!!row.canRetry && !row.applied" (click)="retryRow(row)">Retry</button>-->
                    </td>
                </ng-container>
                <ng-container matColumnDef="machine">
                    <th mat-header-cell *matHeaderCellDef>Machine</th>
                    <td mat-cell *matCellDef="let row">{{ row.memberDn }}</td>
                </ng-container>
                <ng-container matColumnDef="fieldName">
                    <th mat-header-cell *matHeaderCellDef>Subscription</th>
                    <td mat-cell *matCellDef="let row">{{ row.subscriptionType }}</td>
                </ng-container>
                <ng container matColumnDef="op">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let row">
                        <mat-icon>arrow_forward</mat-icon>
                    </td>
                </ng>
                <ng-container matColumnDef="from">
                    <th mat-header-cell *matHeaderCellDef>From</th>
                    <td mat-cell *matCellDef="let row" [title]="row.groupToRemove">
                        {{shorten(row.groupToRemove)}}
                        <ng-container *ngIf="row.groupToRemove === ''">[None]</ng-container>
                    </td>
                </ng-container>
                <ng-container matColumnDef="to">
                    <th mat-header-cell *matHeaderCellDef>To</th>
                    <td mat-cell *matCellDef="let row" [title]="row.groupToAdd">
                        {{shorten(row.groupToAdd)}}
                        <ng-container *ngIf="row.groupToAdd === ''">[None]</ng-container>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="['result', 'machine', 'fieldName', 'from', 'op', 'to'];"></tr>                
                <tr mat-row *matRowDef="let row; columns: ['result', 'machine', 'fieldName', 'from', 'op', 'to']" [class]="{ 'alt-row': altRow[row.memberDn] }"></tr>
            </table>
        </section>
    </div>
</mat-dialog-content>
<mat-dialog-content>
    <section fxLayout="row">
        <div class="warning-about-bad-saves" *ngIf="deltasWithErrors.length > 0 || modelUpdateError" style="width:min-content; flex-grow: 1;">
            <mat-icon>warning</mat-icon><b>Error:</b> some changes didn't save. Transient conditions within Active Directory can sometimes cause this.
            Please consider clicking 'Apply Changes' again to retry. You can also click the error icons to copy the error text and email the application owners or ITS Help Desk if you need more help.
        </div>
    </section>
</mat-dialog-content>
<mat-dialog-actions class="dialog-actions" align="end">
    <button mat-raised-button (click)='cancel()'>{{ closeText }}</button>
    <button mat-raised-button (click)='applyChanges()' *ngIf="!allApplied">Apply Changes</button>
</mat-dialog-actions>