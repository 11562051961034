export class SubscriptionDelta {
    constructor(
        public subscriptionType: string,
        public memberDn: string,
        public action: number,
        public groupToRemove: string,
        public groupToAdd: string) {}

    ui: { applied: boolean, canRetry: boolean, lastError: string | undefined } 
        = { applied: false, canRetry: false, lastError: undefined };
}

export const actionCodeLookup = { "Add": 1, "Remove": 2, "Replace": 3 };