import { Injectable, Inject } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, map, catchError, of } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ActiveUser } from '../models/active-user';
import { apiConfiguration } from 'src/environments/environment';
import { ErrorService } from "../services/error.service";
import { AppError } from "../models/app-error";
import { ErrorTypes } from "../types/error-types";

@Injectable()
export class UserResolver  {
    constructor(
        private router: Router,
        private location: Location,
        private http: HttpClient,
        private errorService: ErrorService
        ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ActiveUser|void> {
        return from(this.requestPage(route))
    }

    requestPage(route: ActivatedRouteSnapshot): Observable<ActiveUser|void> {
        const url = apiConfiguration.apiBaseUrl + `user/current`;
        return this.http.get<any[]>(url).pipe(
            map(raw => new ActiveUser(raw)),
            catchError(err => {
                console.log("in the catch for some reason", err);
            if (err instanceof HttpErrorResponse && err.status === 404) {
                return from(this.showButStay("help/not-found"));
            } else if (err instanceof HttpErrorResponse && err.status === 401) {
                this.errorService.notify(new AppError(ErrorTypes.HTTP_UNAUTH401, err.message, err.statusText));
                return of(undefined);
            } else {
                return from(this.showButStay("help/unknown-error"));
            }
        }));
    }

    async showButStay(loc: string) {
        const path = this.location.path();
        await this.router.navigate([loc], { skipLocationChange: true });
        this.location.replaceState(path);
    }
}
