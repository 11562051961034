<div class="user-tool-bar" fxLayout="row">
    <div fxFlex="5">
        <a [routerLink]="'/'" mat-icon-button><mat-icon>computer</mat-icon></a>
    </div>
    <div fxFlex="6">
        <a [routerLink]="'/orgs'" mat-icon-button><mat-icon>group</mat-icon></a>
    </div>
    <div fxFlex fxFlexAlign="end" class="login-tools">
    Welcome, {{ user?.hawkId }}.
    <!--<a [routerLink]="['/settings']" mat-icon-button><mat-icon>settings</mat-icon></a>-->
    <button (click)="authService.logout()" mat-icon-button><mat-icon>logout</mat-icon></button>
    </div>
</div>