import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { SoftwareService } from 'src/app/services/software.service';
import { SoftwareDeployType, SyncedSoftwareSummary } from 'src/app/models/software';
import { SoftwareDeployToOrgComponent } from '../software-deploy-to-org/software-deploy-to-org.component';

@Component({
  selector: 'app-software-summary-org',
  templateUrl: './software-summary-org.component.html',
  styleUrls: ['./software-summary-org.component.scss']
})
export class SoftwareSummaryOrgComponent implements OnInit {
  public title = "Deployment Summary"
  public groupName: string = "";
  public closeText: string = "Close";
  public syncedSoftware: SyncedSoftwareSummary = new SyncedSoftwareSummary();
  public softwareDeployTypes : SoftwareDeployType[] = [];
  isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { groupName: string },
    private _dialogRef: MatDialogRef<SoftwareDeployToOrgComponent>,
    private _softwareService : SoftwareService
  ) {
    this.groupName = _data.groupName;
  }

  async ngOnInit(){
    console.log('summary org init;')
    this.softwareDeployTypes = await this._softwareService.fetchDeployType();
    await this.loadSoftware(this.groupName);
  }

  async loadSoftware(orgName: string){
    this.isLoading = true;
    try{
      if (orgName){
        let sw = await this._softwareService.fetchAllSyncedSoftwareOrg({ orgName });
        // console.log('loadSoftware05', sw)
        let flatSW = [...sw.asap, ...sw.available, ...sw.exclude, ...sw.mandatory];
        flatSW = flatSW.filter((value,index,self)=>index===self.findIndex((t)=>(t.name === value.name && t.description===value.description && t.deploymentSource===value.deploymentSource)));
        // console.log('loadSoftware10', flatSW)

        flatSW.sort((a,b)=>a.description.localeCompare(b.description) || a.name.localeCompare(b.name)||a.deploymentSource.localeCompare(b.deploymentSource))
        flatSW.forEach(x=>{
          if(x.name.endsWith("-AVL"))
            this.syncedSoftware.available.push(x);
          else if (x.name.endsWith("-ASAP"))
            this.syncedSoftware.asap.push(x);
          else if (x.name.endsWith("-MAN"))
            this.syncedSoftware.mandatory.push(x);
          else if (x.name.endsWith("-XCL"))
            this.syncedSoftware.exclude.push(x);

          if (x && x.deploymentSource && (x.deploymentSource.startsWith("ORG-") ||x.deploymentSource.startsWith("DEPT-")||x.deploymentSource.startsWith("UNIT-")))
            x.deploymentSource="{Org Deployment -- "+x.deploymentSource+"}"
        })
        this.isLoading = false;
        // console.log("flatSW", flatSW);
        // console.log("syncedSummary", this.syncedSoftware)
        return flatSW;
      }
    } catch (ex) {
      console.log(ex)
    }
    this.isLoading = false;
    return [];
  }

  public cancel() {
    this._dialogRef.close();
  }
}
