export class FilterOp {
    value?: string;
    op?: number;
}

export class OrgGroupFilter {
    constructor(
        public page: number = 0,
        public pageSize: number = 10,
        public keywords: string[] = [],
        public filterBy: 'Keys' | 'Field' = 'Field'
    ) {}

    public data?: {
        [key: string]: FilterOp | undefined;
        name?: FilterOp;
        organization?: FilterOp;
        department?: FilterOp;
        unit?: FilterOp;
        building?: FilterOp;
    };

    SortSpecifications: { property: string, direction: string }[] = [];

    /**
     * Serializes to a .NET-compatible Query string. E.g.,
     * filter.page=0&filter.pageSize=10&filter.SortSpecifications[0].Property=start&filter.SortSpecifications[0].Direction=Ascending
     */
    serialize(): string {
        return this._serialize(this);
    }

    private _serialize(obj: any, completed: string[] = [], path: string = "filter", nest?: string): string {
        for (const key in obj) {
            if (obj.hasOwnProperty(key) && key !== this.serialize.name && key !== this._serialize.name) {
                const newpath = !isNaN(+key) ? path + `[${key}]` : path + "." + key;
                if (typeof obj[key] === 'object') {
                    this._serialize(obj[key], completed, newpath);
                } else {
                    completed.push(newpath.concat("=" + obj[key]));
                }
            }
        }
        completed.push("bbnone");
        return completed.join("&");
    }
}