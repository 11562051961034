import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSort, Sort } from '@angular/material/sort';
// import { ComputerFilter } from 'src/app/models/computer-filter';
// import { ComputerCollection } from 'src/app/models/computer-collection';
import { firstValueFrom, map, Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
// import { Computer } from 'src/app/models/computer';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { OrgStructure, OrgStructureDescriptor } from 'src/app/models/org-structure';
import { MatLegacyCheckbox as MatCheckbox } from '@angular/material/legacy-checkbox';
import { OrgStructurePipesFactory } from 'src/app/services/org-struct-pipes.factory';
import { EditSubscriptionsComponent } from '../dialog-bodies/edit-subscriptions/edit-subscriptions.component';
import { Location } from '@angular/common';
import { ComponentType } from '@angular/cdk/portal';
import { ActiveUser } from 'src/app/models/active-user';
import { CommunityAuthService } from 'src/app/services/community-auth.service';
import { OrgGroup } from 'src/app/models/org-group';
import { OrgGroupFilter } from 'src/app/models/org-filter';
import { SoftwareDeployToOrgComponent } from '../dialog-bodies/software-deploy-to-org/software-deploy-to-org.component';
import { OrgDetailsComponent } from '../dialog-bodies/org-details/org-details.component';

@Component({
  selector: 'app-org-chooser',
  templateUrl: './org-chooser.component.html',
  styleUrls: ['./org-chooser.component.scss']
})
export class OrgGroupChooserComponent implements OnInit {
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild("selectAll") selectAll!: MatCheckbox;
  filterForm!: FormGroup;
  orgGroups!: OrgGroup[];
  filter?: OrgGroupFilter;
  loading: boolean = false;
  orgStructureOptions: OrgStructure;
  filtered: { [key: string]: Observable<OrgStructureDescriptor[]> } = {};
  allSelected: boolean = false;
  isScrolledTop: boolean = document.body.scrollTop === 0;
  anySelected: boolean = false;
  selected: OrgGroup[] = [];
  isQuerying: boolean = true;
  private _currentParams: { [key: string]: string | null } = {};
  selectAllIndeterminate: boolean = false;
  selectAllChecked: boolean = false;
  user?: ActiveUser;

  constructor(
    private _fb: FormBuilder,
    private _http: HttpClient,
    private _dialog: MatDialog,
    private _route: ActivatedRoute,
    public router: Router,
    private _location: Location,
    private _orgStructurePipesFactory: OrgStructurePipesFactory,
    public authService: CommunityAuthService
  ) {
    this.orgStructureOptions = _route.snapshot.data["orgStructureOptions"];
    this.filterForm = this._fb.group({ name: [''], org: [''], dept: [''], unit: ['']});
    this._route.data.subscribe(this.userChanges.bind(this));
  }

  private userChanges({user}:{user:ActiveUser}|Data) {
    console.log(user);
    this.user = user;
  }

  async ngOnInit() {
    this._route.parent?.data.subscribe(data => {
      this.isQuerying = false;
      this.orgGroups = data["results"];
      this.orgStructureOptions = data["orgStructureOptions"];
      this.setupFiltering();
      this.adjustSelectAll();
    });
    this._route.parent?.queryParams.subscribe(p => {
      this._currentParams = p;
      this.filterForm.get('name')?.setValue(p["name"]);
      const abbrev: {[key: string]: string} = { 'organization': 'org', 'department': 'dept', 'unit': 'unit'};
      for(let key of ['organization', 'department', 'unit']) {
        if (! p[key]) continue;
        this.filterForm.get(abbrev[key])?.setValue(p[key]);
      }
    });
  }
  
  async ngAfterViewInit() {
    this.filter = new OrgGroupFilter(0, 1);
    this.setSort(this.filter, { direction: this.sort.direction, active: this.sort.active }, false);
    // this.sort.sortChange.pipe(tap(this.setSort.bind(this, this.filter))).subscribe();
    // this.paginator.page.pipe(tap(this.setPage.bind(this, this.filter))).subscribe();
    this.filterForm.valueChanges.pipe(tap(this.setFilter.bind(this, this.filter))).subscribe();
    await this.showDialogIfRequested(this._route.snapshot.data['dialog']);
    // this.paginator.pageSize = localStorage["eustPageSize"];
    this._route.parent?.queryParams.subscribe(p => {
      // localStorage["eustPageSize"] = this.paginator.pageSize = (p["pageSize"] ?? localStorage["eustPageSize"] ?? '10');
      this.sort.active = p["sortOn"] ?? "name";
      this.sort.direction = p["sortDir"] ?? "asc";
    });
  }

  private setupFiltering() {
    this.filtered = this._orgStructurePipesFactory.build(this.filterForm, this.orgStructureOptions);
    for (let key in this.filtered) {
      this.filtered[key] = this.filtered[key].pipe(
        map(orgs => orgs.filter(org => this.byRelated(key.toUpperCase(), org))),
        map(orgs => orgs.sort(this.sortByNameDesc)));
    }
  }

  private async showDialogIfRequested(dialog: string) {
    switch (dialog) {
      // case 'edit-org-structure':
      //   await this.showEditOrgStructure(this._route.snapshot.parent?.data['computers'], false);
      //   break;
      // case 'edit-subscriptions':
      //   await this.showEditSubscriptions(this._route.snapshot.data['orggroups'], false);
      //   break;
      case 'show-more-info':
        await this.showMoreInfo(this._route.snapshot.data['orggroups'], false);
        break;
      default:
    }
  }

  private sortByNameDesc(a: OrgStructureDescriptor, b: OrgStructureDescriptor) {
    if (!a.name) return 1;
    if (!b.name) return -1
    return a.name < b.name ? -1 : 1;
  }

  private byRelated(which: string, o: OrgStructureDescriptor) {
    if (which == 'ORG')
      return true;

    let org = this.filterForm.get('org')?.value?.toUpperCase() ?? '';
    org = org == '' ? null : org;
    if (which == "DEPT") {
      return o.name?.toUpperCase().startsWith(`DEPT-${org ?? ''}`);
    }

    let dept = this.filterForm.get('dept')?.value?.toUpperCase() ?? '';
    dept = dept == '' ? null : dept;

    let unit = this.filterForm.get('unit')?.value?.toUpperCase() ?? '';
    unit = unit == '' ? null : unit;

    const matcher = new RegExp(`^UNIT-${org ?? '.+'}-${dept ?? '.+'}`);
    return o.name != null && matcher.test(o.name.toUpperCase());
  }

  handleFormKeydown(ev: any) {
    switch (ev.key) {
      case "Enter": this.search(); break;
    }
  }

  public async search() {
    this.isQuerying = true;
    const queryParams: { [key: string]: string | number | undefined | null } = {};
    for(let key in this.filter?.data) {
      //console.log("key=", key, '; value=', this.filter?.data[key]?.value, this._currentParams[key])
      if (this.filter?.data[key]?.value) {
        queryParams[key] = this.filter?.data[key]?.value;
      } else {
        queryParams[key] = null;
      }
    }
    queryParams['pageSize'] = this.filter?.pageSize ?? 10;
    queryParams['sortOn'] = this.filter?.SortSpecifications[0]?.property ?? "name";
    queryParams['sortDir'] = this.filter?.SortSpecifications[0]?.direction ?? "asc";
    queryParams['selected'] = null;
    queryParams['name'] = this.filter?.data?.name?.value;
    console.log('query', queryParams);
    this.router.navigate(['/orgs'], { queryParams, queryParamsHandling: 'merge' })
  }

  private async showDialog<T>(comp: ComponentType<T>, config: MatDialogConfig, virtualPath: string[], showVirtualPath: boolean = true, queryParams?: { selected: string }) {
    console.log(typeof(comp));
    virtualPath.unshift('/orgs');
    if (showVirtualPath) {
      this._location.go(this.router.createUrlTree(virtualPath, { queryParams, queryParamsHandling: 'merge' }).toString());
    }
    config.data.user = this.user;
    const value = await firstValueFrom(this._dialog.open(comp, config).afterClosed());
    
    const queryParamsToRemove = { 'selected': null };
    const root = this.router.createUrlTree(['/orgs'], { queryParams: queryParamsToRemove, queryParamsHandling: 'merge' }).toString()
    this._location.go(root);    
    return value;
    return;
  }

  public async showMoreInfo(orgs: OrgGroup[], showVirtualPath: boolean = true) {
    const config = new MatDialogConfig();
    config.panelClass = 'comp-details-dialog';
    config.data = { orgs, orgOptions: this.orgStructureOptions, subOptions: this._route.snapshot.data['subscriptionOptions'] };
    await this.showDialog(OrgDetailsComponent, config, [orgs[0].name, 'details'], showVirtualPath);
  }

  public applyLocalUpdates(updates: OrgGroup[]) {
    for (let i=0; i<updates.length; i++) {
      const index = this.orgGroups.findIndex(c => c.name == updates[i].name)
      if (index >= 0) {
        //console.log(`Splicing ${index},1 with ${update[0]}`)
        this.orgGroups = [...this.orgGroups.slice(0, index), updates[i], ...this.orgGroups.slice(index + 1)];
        //console.log(this.computers[index]);
      } else {
        console.log(updates[i]);
        console.warn("Couldn't find matching computer to apply local update: ", i, updates[i].name);
      }
    }
  }

  public async showEditSubscriptions(orgs: OrgGroup[], showVirtualPath: boolean = true) {
    const config = new MatDialogConfig();
    config.panelClass = 'comp-details-dialog';
    config.width = "40vw";
    config.height = "75vh";
    config.data = { orgs, options: this._route.snapshot.data['subscriptionOptions'] };
    //const queryParams = orgs.reduce((prev, cur) => { prev['selected'].push(cur); return prev; }, { selected: new Array<Computer>() })
    const updates = await this.showDialog(EditSubscriptionsComponent, config, [orgs[0].name, 'subscriptions'], showVirtualPath, /*queryParams*/);
    if (updates?.length) {
      this.applyLocalUpdates(updates);
    }
  }

  public async showEditManySubscriptions(orgs: OrgGroup[], showVirtualPath: boolean = true) {
    const config = new MatDialogConfig();
    config.panelClass = 'comp-details-dialog';
    config.width = "40vw";
    config.height = "75vh";
    config.data = { orgs, options: this._route.snapshot.data['subscriptionOptions'] };
    const queryParams = { selected: orgs.map(c => c.name).join(',') };
    const updates = await this.showDialog(EditSubscriptionsComponent, config, ['--selected--', 'subscriptions'], showVirtualPath, queryParams);
    if (updates?.length) {
      this.applyLocalUpdates(updates);
    }
  }

  public async showDeploySoftwareToOrg(orgs: OrgGroup[], showVirtualPath: boolean = true) {
    console.log('deploy', orgs);
    const config = new MatDialogConfig();
    config.panelClass = 'org-details-dialog';
    config.width = "75vw";
    config.height = "90vh";
    config.data = { orgs, options: this._route.snapshot.data['subscriptionOptions'] };
    const queryParams = { selected: orgs.map(c => c.name).join(',') };
    await this.showDialog(SoftwareDeployToOrgComponent, config, [], showVirtualPath, queryParams);
  }

  public toggleSelectAll() {
    const selected = this.selectAll.checked;
    for (let key in this.orgGroups) {
      this.orgGroups[key].ui['selected'] = selected;

    }
    this.anySelected = selected;
    this.selected = selected ? [...this.orgGroups] : [];
  }

  public adjustSelectAll() {
    let all = true;
    let some = false;
    this.selected = [];
    for (let org of this.orgGroups) {
      let thisone = org.ui['selected'] === true;
      all &&= thisone;
      some ||= thisone;
      if (thisone) this.selected.push(org);
    }

    this.selectAllIndeterminate = some;
    this.selectAllChecked = all;
    this.anySelected = some;
  }

  private setFilter(filter: OrgGroupFilter, v: { [ctrl: string]: string }) {
    filter.data = {};
    let name = '';
    if (typeof(v["unit"]) != 'undefined' && v["unit"])
      name = "UNIT-" + v["org"] + "-" + v["dept"] + "-" + v["unit"] ;
    else if(typeof(v["dept"]) != 'undefined' && v["dept"])
      name = "DEPT-" + v["org"] + "-" + v["dept"];
    else if (typeof(v["org"]) != 'undefined' && v["org"])
      name = "ORG-" + v["org"];
    filter.data.name = { value: name };
    console.log(v["dept"]);
    console.log(filter.data.name);
    // filter.data.organization = { value: v["org"] };
    // filter.data.building = { value: v["bldg"] };
    // filter.data.unit = { value: v["unit"] };
    // filter.data.department = { value: v["dept"] };
  }

  private async setSort(filter: OrgGroupFilter, v: Sort, shouldSearch: boolean = true) {
    if (v.direction === "") {
      filter.SortSpecifications = [];
      return;
    }

    filter.SortSpecifications = [
      { property: v.active, direction: v.direction }
    ];
    console.log("Setting sort: " + v.active + " " + v.direction);
    if (shouldSearch)
      await this.search();
  }

  private async setPage(filter: OrgGroupFilter) {
    filter.page = 0;
    filter.pageSize = 1;
    await this.search();
  }

  @HostListener("window:scroll")
  public onScroll() {
    console.log("scrolling", document.body.scrollTop);
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;
    this.isScrolledTop = verticalOffset < 100;
  }

  public scrollTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }



}
