import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, firstValueFrom } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { apiConfiguration } from 'src/environments/environment';
import { OrgStructure } from "../models/org-structure";
import { SubscriptionDictionary } from "../models/subscription";

@Injectable()
export class SubscriptionResolver  {
    constructor(
        private router: Router,
        private location: Location,
        private http: HttpClient)
    {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<SubscriptionDictionary | void> {
        return from(this.request(route))
    }

    async request(route: ActivatedRouteSnapshot): Promise<SubscriptionDictionary | void> {
        const p = route.params;
        try {
            const url = apiConfiguration.apiBaseUrl + `subscriptions`;
            const raw = await firstValueFrom(this.http.get<SubscriptionDictionary>(url));
            return new SubscriptionDictionary(raw);
        } catch (err) {
            console.error(err);
            return await this.notfound(`We cannot find this endpoint. This is likely an app configuration issue.`);
        }
    }

    
    async notfound(msg: string): Promise<SubscriptionDictionary | void> {
        const path = this.location.path();
        await this.router.navigate(['notfound'], { skipLocationChange: true, queryParams: { message: msg}});
        this.location.replaceState(path);
    }
}
