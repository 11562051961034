<section class="container">
    <div class="content-container">
        <h1 class="content-heading">Internal error</h1>
        <div class="content-body">
            <p>
                Sorry, we seem to be having some problems. Please check back later. If the problems persist, please contact the ITS
                Help Desk.
            </p>
            <div *ngIf='message'>
                <h2>Details</h2>
                <p>
                    {{ message }}
                </p>
            </div>
        </div>  
    </div>
</section>