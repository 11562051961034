import { EventEmitter } from '@angular/core';

export type UISpecificOrg = {
    -readonly [K in keyof OrgGroup]: OrgGroup[K];    
    } & {
        changes: EventEmitter<[string, any]>;
    };

export class OrgGroup {
    [key: string]: any;
    constructor(public raw: any) {
        this.id = raw.name;

        // this.distinguishedName = this.raw.distinguishedName;
        this.name = this.raw.name;
        // this.department = this.raw.department;
        // this.unit = this.raw.unit;
        // this.memberOf = this.raw.memberOf;
        this.description = this.raw.description;
        this.created = new Date(this.raw.whenCreated);
        this.lastupdated = new Date(this.raw.whenChanged);
        this.distinguishedName = this.raw.distinguishedName;
        this.selected = false;

        const self = this;
        this.ui = new Proxy<UISpecificOrg>( { ...this, changes: new EventEmitter<[string, any]>() }, {
            set: function(target, prop: string, value, receiver) {
                self[prop] = target[prop] = value;
                target.changes.emit([prop, value]);
                return true;
            }
        });
    }

    readonly ui: UISpecificOrg;
    readonly name: string;
    readonly description: string;
    readonly distinguishedName: string;
    readonly created: Date;
    readonly lastupdated: Date;
    selected: boolean;

    // Fields for updating/monitoring for changes...
    readonly id: number;

    asApiModel(): Omit<this, keyof UISpecificOrg> {
        const { ui, ...picked } = this;
        return <any>picked;
    }
}