import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { AppError } from '../models/app-error';

@Injectable({ providedIn: "root" /* root = NG singleton */ })
export class ErrorService {
    private _errors = new Subject<AppError>();
    get errors$() { return this._errors.asObservable() }

    public notify(error: AppError) {
        this._errors.next(error);
    }
}