import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, map, catchError, of } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { apiConfiguration } from 'src/environments/environment';
import { OrgGroupFilter } from "../models/org-filter";
import { ErrorService } from "../services/error.service";
import { AppError } from "../models/app-error";
import { ErrorTypes } from "../types/error-types";
import { OrgGroup } from "../models/org-group";

@Injectable()
export class OrgGroupsResolver  {
    constructor(
        private router: Router,
        private location: Location,
        private http: HttpClient,
        private errorService: ErrorService)
    {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<OrgGroup[] | void> {
        return this.request(route);
    }

    request(route: ActivatedRouteSnapshot): Observable<OrgGroup[] | void> {
        const p = route.queryParams;
        try {
            const filter = new OrgGroupFilter();
            filter.data = {};
            let hasValues = false;
            for(let key in p) {
                if (! p[key] || key == 'pageSize' || key.startsWith('sort')) continue;
                filter.data[key] = { value: p[key] };
                hasValues = true;
            }
            filter.pageSize = p['pageSize'] ?? 10;
            filter.SortSpecifications = [ { property: p['sortOn'], direction: p['sortDir'] === "desc" ? "Descending" : "Ascending" }];
            if (! hasValues)
                return from([[]]);

            return this.http
                .get<any[]>(apiConfiguration.apiBaseUrl + "orgstructure/filter?" + filter.serialize())
                .pipe(
                    map(results => results.map((cdp: any | OrgGroup) => new OrgGroup(cdp))),
                    catchError<any, any>(err => {
                    if (err instanceof HttpErrorResponse && err.status === 401) {
                        this.errorService.notify(new AppError(ErrorTypes.HTTP_UNAUTH401, err.message, err.statusText));
                        return of(undefined);
                    }
                    
                    return from(this.router.navigate(['help/unknown-error']));
                }));
        } catch (err) {
            console.error(err);
            return from(this.notfound(`We cannot find this endpoint. This is likely an app configuration issue.`));
        }
    }

    
    async notfound(msg: string): Promise<OrgGroup[] | void> {
        const path = this.location.path();
        await this.router.navigate(['notfound'], { skipLocationChange: true, queryParams: { message: msg}});
        this.location.replaceState(path);
    }
}
