import { Component, Inject} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SoftwareDeployToComputerComponent } from '../software-deploy-to-computer/software-deploy-to-computer.component';
import { Software, SoftwareDeployType } from 'src/app/models/software';
import { SoftwareService } from 'src/app/services/software.service';
import { SoftwareDeployToOrgComponent } from '../software-deploy-to-org/software-deploy-to-org.component';

@Component({
  selector: 'app-confirm-software-changes-org',
  templateUrl: './confirm-software-changes-org.component.html',
  styleUrls: ['./confirm-software-changes-org.component.scss']
})
export class ConfirmSoftwareChangesOrgComponent {
  public title = "Confirm software deployment"
  public deployType: SoftwareDeployType;
  public software: Software[];
  public groupId: string = "";
  public allApplied: boolean = false;
  public someApplied: boolean = false;
  public closeText: string = "Cancel";
  public isWritingADGroup: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { software: Software[], groupId: string, deployType: SoftwareDeployType },
    private _dialogRef: MatDialogRef<SoftwareDeployToOrgComponent>,
    private _softwareService : SoftwareService
  ) {
    this.software = _data.software;
    this.deployType = _data.deployType;
    this.groupId = _data.groupId;
  }

  shorten(dn: string) {
    return dn.substring(3, dn.indexOf(",", 4));
  }

  public cancel() {
      this._dialogRef.close();
  }

  public async applyChanges() {
    console.log('applychangesorg', this.groupId);
    if (this.groupId && this.deployType && this.software ){
      this.isWritingADGroup = true;
      await this._softwareService.syncSoftwareOrg({ deployType: this.deployType.type, groupName: this.groupId, softwareName: this.software.map(x => x.name ?? '') });
      this.isWritingADGroup = false;
    }
    this.updateSaveState();
  }

  private updateSaveState() {
    this.closeText = "Close";
    this.someApplied = true;
    this.allApplied = true;
  }
}
