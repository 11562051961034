import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { tap } from 'rxjs';
import { Computer } from 'src/app/models/computer';
import { OrgStructure, OrgStructureDescriptor } from 'src/app/models/org-structure';
import { SubscriptionDescriptor } from 'src/app/models/subscription';
import { subscriptionCodeToTitle } from 'src/app/models/subscription-codes';

@Component({
  selector: 'app-computer-details',
  templateUrl: './computer-details.component.html',
  styleUrls: ['./computer-details.component.scss']
})
export class ComputerDetailsComponent {
  title: string;
  groups?: { name: string, isSub: boolean, isOrg: boolean }[];
  filterForm: FormGroup = this._fb.group({ showGroupFullDn: [false], showOrgSubs: [false] });
  fieldArray: Field[] = [];
  subTags = [...Object.keys(subscriptionCodeToTitle), "GPO-Lockscreen-ADA"];
  orgTags = ["ORG", "DEPT", "UNIT", "BLDG"];
  subscriptions?: { name: string; isSub: boolean; isOrg: boolean; }[];
  orgs?: { name: string; isSub: boolean; isOrg: boolean; }[];
  groupByName: { [key: string]: { key: string; name?: string | undefined; description?: string | undefined; }; };

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { groups: string[], computers: Computer[], title: string, orgOptions: { [key: string]: OrgStructureDescriptor[] }, subOptions: { [key: string]: SubscriptionDescriptor[] } },
    private _fb: FormBuilder,
    private dialogRef: MatDialogRef<ComputerDetailsComponent>
  ) {
    const allGroups = { ..._data.orgOptions, ..._data.subOptions };
    type groupViewModel = { key: string, name?: string, description?: string };
    this.groupByName = Object
      .keys(allGroups)
      //.map(key => { return { key, ...allGroups[key] }; })
      .reduce((acc, key) => acc.concat(allGroups[key].map(g => { return { key, ... g}; })), <groupViewModel[]>[])
      .reduce((acc, group) => { if (group.name) acc[group.name] = group; return acc;}, <{[key:string]: groupViewModel}> {})
    console.log(_data.orgOptions, _data.subOptions, this.groupByName);
    this.title = _data?.title ?? "Computer Details";
    this.updateGroups(false, false);
    this.fieldArray.push(new Field("DN", _data.computers[0].distinguishedName));
    this.fieldArray.push(new Field("whenCreated", _data.computers[0].created, "date"));
    this.fieldArray.push(new Field("whenChanged", _data.computers[0].lastupdated, "date"));
  }

  public ngAfterViewInit() {
    this.filterForm.valueChanges.pipe(tap(v => this.updateGroups(v['showGroupFullDn'], v['showOrgSubs']))).subscribe();
  }

  public updateGroups(showGroupFullDn: boolean, showOrgSubs: boolean) {
    let groups = this._data.computers[0].memberOf.map(group => {
      return {
        name: showGroupFullDn ? group : group.split(",")[0].replace("CN=", ""),
        prefix: group.replace("CN=", "").split("-")[0],
        isSub: this.subTags.find(c => group.startsWith(`CN=${c}`)) != null,
        isOrg: this.orgTags.find(c => group.startsWith(`CN=${c}`)) != null
      };
    });
    const byNameAsc = (a: { name: string }, b: { name: string }) => a.name < b.name ? -1 : 1;
    this.subscriptions = groups.filter(g => g.isSub).sort(byNameAsc);
    this.orgs = groups.filter(g => g.isOrg).sort((a, b) => this.orgTags.indexOf(a.prefix) < this.orgTags.indexOf(b.prefix) ? -1 : 1);

    /*if (!showOrgSubs) {
      groups = groups.filter(g => !(g.isOrg || g.isSub));
    }
    this.groups = groups.sort((a, b) => {
      if (a.isOrg == b.isOrg) {
        if (a.isSub == b.isSub) {
          return a.name < b.name ? -1 : 1;
        } else {
          return a.isSub ? 1 : -1;
        }
      } else {
        return a.isOrg ? 1 : -1;
      }
    });*/
  }

  cancel(): void {
    this.dialogRef.close({});
  }
}

export class Field {
  constructor(
    public fieldName: string,
    public fieldValue: string | Date,
    public fieldType: "string" | "date" = "string") { }
}
