import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialog, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { firstValueFrom } from 'rxjs';
import { Computer } from 'src/app/models/computer';
import { actionCodeLookup, SubscriptionDelta } from 'src/app/models/subscription-delta';
import { apiConfiguration } from 'src/environments/environment';
import { EditOrgStructureComponent } from '../edit-org-structure/edit-org-structure.component';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-confirm-changes',
  templateUrl: './confirm-changes.component.html',
  styleUrls: ['./confirm-changes.component.scss']
})
export class ConfirmChangesComponent implements OnInit {
  public title = "Confirm changes"
  public deltas!: SubscriptionDelta[];
  public deltasWithErrors: SubscriptionDelta[] = [];
  public model?: Computer;
  public modelUpdateError?: string;
  public original?: Computer;
  public altRow: { [key: string]: boolean } = {};
  public actions = actionCodeLookup;
  public opIcons: { [key: string]: string } = {
    1 : "add_circle_outline",
    2 : "remove_circle_outline",
    3 : "edit"
  };
  public allApplied: boolean = false;
  public someApplied: boolean = false;
  public closeText: string = "Cancel";

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { deltas: SubscriptionDelta[], model: Computer, computer: Computer, localUpdates: (Computer)[] },
    private _fb: FormBuilder,
    private _http: HttpClient,
    private _dialogRef: MatDialogRef<EditOrgStructureComponent>,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog
  ) {
    this.deltas = _data.deltas;
    this.model = _data.model;
    this.original = _data.computer;
    this.altRow = Array
      .from(new Set(this.deltas.map(d => d.memberDn))) // distinct
      .reduce((acc: { [key: string]: boolean }, cur: string, i: number) => {
        acc[cur] = i % 2 == 1; return acc;
        },
        {}
      );
  }

  ngOnInit(): void {
  }

  shorten(dn: string) {
    return dn.substring(3, dn.indexOf(",", 4));
  }

  public cancel() {
    if (! this.someApplied) {
      this._dialogRef.close();
    } else {
      const updated = this.deltas
        .filter(d => d.ui.applied)
        .map(d => this._data.localUpdates.find(u => u.name == d.memberDn));
      const updates = [ ...new Set(updated) /* make distinct */ ];
      this._dialogRef.close(updates);
    }
  }
  public async applyChanges() {
    if (this.model) {
      try {
        const result = await firstValueFrom(this._http.put(apiConfiguration.apiBaseUrl + "Computer", this.model));
        this.modelUpdateError = undefined;
      } catch (err: unknown) {
        console.debug(err);
        if (err instanceof HttpErrorResponse) {
          this.modelUpdateError = `(${err.status}) ${err.message}\n${this.anonymize(err.error)}`;
        } else if (err instanceof Error) {
          this.modelUpdateError = `${err.name}: ${err.message}\n${err.stack}`;
        } else {
          this.modelUpdateError = err?.toString() ?? "Unknown error";
        }
      }
    }
    await Promise.all(this.deltas.map(async (op) => await this.applyRow(op)));
    this.updateSaveState();
    this.deltasWithErrors = this.deltas.filter(d => d?.ui?.lastError);
  }
  
  public copyToClipboard(text: string) {
    navigator.clipboard.writeText(text);
    this._snackBar.open("Copied error text to clipboard!");
  }

  private updateSaveState() {
    const notApplied = this.deltas.filter(d => !d.ui.applied);
    switch (notApplied.length) {
      case 0:
        this.closeText = "Close";
        this.someApplied = true;
        this.allApplied = true;
        break;
      case this.deltas.length:
        this.someApplied = false;
        this.closeText = "Cancel";
        break;
      default:
        this.someApplied = true;
        this.closeText = "Cancel remaining";
    }
  }

  async applyRow(op: SubscriptionDelta) {
    if (op?.ui?.applied) return;

    try {
      let localUpdate = this._data.localUpdates.find(u => u.name == op.memberDn);
      const result = await firstValueFrom(this._http.put(apiConfiguration.apiBaseUrl + "Computer/change-group", op));
      op.ui.applied = true;
      this.spliceDeltaIntoLocal(op, localUpdate!.memberOf);
      if (this.deltas.find(d => !d.ui.applied) === undefined) {
        this.allApplied = true;
      }
    } catch(ex: any) {
      console.error("Error while applying update", ex, op);
      if (ex instanceof HttpErrorResponse) {
        op.ui.lastError = `(${ex.status}) ${ex.message}\n${this.anonymize(ex.error)}`;
      } else {
        op.ui.lastError = (ex instanceof Error) ? ex?.message : JSON.stringify(ex);
      }
      op.ui.canRetry = true;
    }
  }

  private anonymize(identifiable: string) {
    return identifiable?.toString().replace(/Authorization: .*/i, '') ?? identifiable;
  }

  private spliceDeltaIntoLocal(op: SubscriptionDelta, memberOf: string[]) { 
    if (op?.action === actionCodeLookup["Add"]) {
      memberOf.push(op.groupToAdd);
    }
    if (op?.action === actionCodeLookup["Remove"]) {
      const index = memberOf.findIndex(m => m === op.groupToRemove);
      if (index >= 0) {
        memberOf.splice(index, 1);
      }
    }
    if (op?.action === actionCodeLookup["Replace"]) {
      const index = memberOf.findIndex(m => m === op.groupToRemove);
      memberOf.splice(index, 1, op.groupToAdd);
    }
  }
}
