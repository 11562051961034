import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { ActiveUser } from 'src/app/models/active-user';
import { debounceTime, delay, distinctUntilChanged, startWith, tap } from 'rxjs/operators';
import { CommunityAuthService } from 'src/app/services/community-auth.service';

@Component({
  selector: 'title-bar',
  templateUrl: './title-bar.component.html',
  styleUrls: ['./title-bar.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class TitleBarComponent implements OnInit {
  authenticated: boolean = false;
  isImpersonating: boolean = false;
  accountName: string = "N/A";
  @Input() user?: ActiveUser;
  
  constructor(
    public authService: CommunityAuthService
  ) { }

  ngOnInit(): void {
    this.authenticated = this.authService.isAuthenticated();
    this.authService.isAuthenticated$.pipe(
        delay(25), // enough to prevent "Expression has changed after it was checked."
        distinctUntilChanged(),
        tap(this.authChange.bind(this))
    ).subscribe();
  }

  endImpersonation() {
    this.authService.endImpersonation();
    location.reload();
  }

  authChange(a: boolean) {
    this.authenticated = a;
    this.isImpersonating = this.authService.isImpersonating;
    this.accountName = this.authService.applicationUsername;
  }

  login() {
    this.authService.login(undefined, true);
  }

  refresh() {
    this.authService.refresh();
  }

  logout() {
    this.authService.endImpersonation();
    this.authService.logout();
  }
}
