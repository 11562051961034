
export class SubscriptionDictionary {
    [key: string]: SubscriptionDescriptor[];

    constructor(_raw: SubscriptionDictionary | undefined) {
        if (_raw) {
            for(let key in _raw) {
                this[key] = _raw[key].map(o => new SubscriptionDescriptor(o));
            }
        }
    }
}

export class SubscriptionDescriptor {
    constructor(raw: SubscriptionDescriptor) {
        this.name = raw.name;
        this.distinguishedName = raw.distinguishedName;
        this.description = raw.description;
        this.isDefault = raw.isDefault;
        this.isRecommended = raw.description?.includes("[Recommended") ?? false;
    }

    public readonly name?: string;
    public readonly distinguishedName?: string;
    public readonly description?: string;
    public readonly isDefault: boolean = false;
    public readonly isRecommended: boolean = false;

    public ui: { selected: boolean } = { selected: false };
  }