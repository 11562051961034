import { Component, OnInit, Input } from '@angular/core';
import { ActiveUser } from 'src/app/models/active-user';

@Component({
  selector: 'helpdesk-link',
  templateUrl: './helpdesk-link.component.html',
  styleUrls: ['./helpdesk-link.component.scss']
})
export class HelpdeskLinkComponent implements OnInit {
  @Input() user?: ActiveUser;
  @Input() href?: string;

  constructor() { }

  ngOnInit() {
    console.debug(this.user, this.user?.isAdmin, this.href);
  }

}
