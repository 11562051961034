export class ActiveUser {
    constructor(private user: any) {
        this.raw = user;
        this.hawkId = this.raw?.hawkId;
    }

    get id(): number { return this.raw.id; }
    readonly raw: { accountRoles: any[] } & any;
    readonly hawkId: string;
    get isImpersonated(): boolean { return false; }
    hasAnyRole(...roles: string[]): boolean {
        return !!(this.raw
            && this.raw.userRoles
            && this.raw.userRoles.find((e: any) => !!roles.find(r => r.toUpperCase() === e.role.roleName.toUpperCase())));
    }
    get isAdmin(): boolean { return this.hasAnyRole("admin"); }
    get iCalAccessKey(): string { return this.raw.iCalAccessKey || null; }
    get iCalEnabled(): boolean { return this.raw.iCalEnabled || false; }
    get displayName(): string { return this.raw.displayName; }
    get activeMailbox(): string { return this.raw.calendarID; }
}