import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpEvent, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CommunityAuthService } from '../services/community-auth.service';
import { ErrorService } from '../services/error.service';
import { AppError } from '../models/app-error';
import { ErrorTypes } from '../types/error-types';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthHeaderInterceptor implements HttpInterceptor {
  constructor(
    private _authService: CommunityAuthService,
    private _errorService: ErrorService
  ) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Adding JWT Token "Authorization: Bearer" is handled by OAuth module (via config in app.module.ts)
    // We only use this for impersonation.
    let headers = req.headers;
    if (this._authService.isImpersonating) {
      headers = headers.set('X-Impersonate', this._authService.impersonatedHawkId);
    }

    const cloned = req.clone({ headers }); // clone it because Requests are immutable.
    return next.handle(cloned).pipe(tap(<(x: any) => void>this._success.bind(this), this._failure.bind(this)));
  }

  private _success(): void {

  }

  private _failure(err: any): void {
    if (err instanceof HttpErrorResponse && err.status !== 401) {
      return;
    }
    console.log(err);
    if (err?.error?.code === null)
      this._errorService.notify(new AppError(ErrorTypes.HTTP_UNAUTH401, "Unauthorized access.", err));
  }

}
