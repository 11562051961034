import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef } from '@angular/material/dialog';
import { SoftwareService } from 'src/app/services/software.service';
import { SoftwareDeployToComputerComponent } from '../software-deploy-to-computer/software-deploy-to-computer.component';
import { Software } from 'src/app/models/software';

@Component({
  selector: 'app-software-summary',
  templateUrl: './software-summary.component.html',
  styleUrls: ['./software-summary.component.scss']
})
export class SoftwareSummaryComponent implements OnInit {
  public title = "Deployment Summary"
  public computerName: string="";
  public closeText: string = "Close";
  public syncedSoftware: Software[] = [];
  isLoading = false;
  displayedColumns : string[] = ['description', 'name', 'deploymentSource'];

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { computerName: string },
    private _dialogRef: MatDialogRef<SoftwareDeployToComputerComponent>,
    private _softwareService : SoftwareService
  ) {
    this.computerName = _data.computerName;
  }

  async ngOnInit(){
    this.syncedSoftware = await this.loadSoftware(this.computerName);
  }

  async loadSoftware(computerName: string): Promise<Software[]>{
    this.isLoading = true;
    try{
      if (computerName){
        let sw = await this._softwareService.fetchAllSyncedSoftware({ computerName });
        let flatSW = [...sw.asap, ...sw.available, ...sw.exclude, ...sw.mandatory];
        flatSW = flatSW.filter((value,index,self)=>index===self.findIndex((t)=>(t.name === value.name && t.description===value.description && t.deploymentSource===value.deploymentSource)));
        flatSW.sort((a,b)=>a.description.localeCompare(b.description) || a.name.localeCompare(b.name)||a.deploymentSource.localeCompare(b.deploymentSource))
        flatSW.forEach(x=>{
          if(x.name.endsWith("-AVL"))
            x.name="Available";
          else if (x.name.endsWith("-ASAP"))
            x.name="ASAP";
          else if (x.name.endsWith("-MAN"))
            x.name="Mandatory";
          else if (x.name.endsWith("-XCL"))
            x.name="Exclude";

          if (x && x.deploymentSource && (x.deploymentSource.startsWith("ORG-") ||x.deploymentSource.startsWith("DEPT-")||x.deploymentSource.startsWith("UNIT-")))
            x.deploymentSource="{Org Deployment -- "+x.deploymentSource+"}"
        })
        this.isLoading = false;
        return flatSW;
      }
    } catch (ex) {
      console.log(ex)
    }
    this.isLoading = false;
    return [];
  }

  public cancel() {
    this._dialogRef.close();
  }
}
