import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter, tap, pairwise, debounceTime } from 'rxjs';
import { SessionExpiredComponent } from './components/dialog-bodies/session-expired/session-expired.component';
import { CommunityAuthService } from './services/community-auth.service';
import { ErrorService } from './services/error.service';
import { ErrorTypes } from './types/error-types';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'ecm.endusersupporttools';
  loading: boolean = false;
  loadingMessage: string = "Loading...";

  constructor(
    public authService: CommunityAuthService,
    public oauthService: OAuthService,
    public errorService: ErrorService,
    private _matDialog: MatDialog,
    private _router: Router
  ) {
    errorService.errors$.pipe(
      filter(appErr => appErr.code == ErrorTypes.HTTP_UNAUTH401),
      tap(this._sessionExpired.bind(this))
    ).subscribe();

    authService.isAuthenticated$.pipe(
      pairwise(),
      filter(([a, b]) => a && !b),
      tap(this._sessionExpired.bind(this)))
      .subscribe();

    this._router.events
      .pipe(
        filter(e => e instanceof NavigationStart && (!e.url.startsWith("/computers") || !this._router.routerState.snapshot.url?.startsWith("/computers"))))
      .subscribe(e => this.loading = true);
    this._router.events
      .pipe(filter(e => !![NavigationEnd, NavigationCancel, NavigationError].find(n => e instanceof n)))
      .subscribe(e => this.loading = false);
  }

  private _sessionExpired() {
    this._matDialog.open(SessionExpiredComponent, { disableClose: true })
  }
}