import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ActiveUser } from 'src/app/models/active-user';
import { Computer } from 'src/app/models/computer';
import { SoftwareService } from 'src/app/services/software.service';
import { Subject, debounceTime, distinctUntilChanged, firstValueFrom } from 'rxjs';
import { ConfirmSoftwareChangesComponent } from '../confirm-software-changes/confirm-software-changes.component';
import { Software, SoftwareDeployType } from 'src/app/models/software';
import { SoftwareSummaryComponent } from '../software-summary/software-summary.component';

@Component({
  selector: 'app-software-deploy-to-computer',
  templateUrl: './software-deploy-to-computer.component.html',
  styleUrls: ['./software-deploy-to-computer.component.scss']
})
export class SoftwareDeployToComputerComponent implements OnInit {
  title: string = "Deploy software to computer";
  computer!: string;
  computers!: Computer[];
  computersDescription!: string;
  form!: FormGroup;
  showSetDesc: boolean = false;
  computedDesc: string = "";
  user: ActiveUser;
  updateEnabled: boolean = false;
  softwareDeployType: string="APD-*-AVL";
  softwareDeployTypes: SoftwareDeployType[] = [];
  softwareSource: Software[] = [];
  softwareSourceDisplay: Software[] = [];
  swToAdd: string[] = [];
  swToAddPrev: string[] = [];
  swToAddRangeSelection01 : string = "";
  swToAddRangeSelection02 : string = "";
  softwareDest: Software[] = [];
  swToRemove: string[] = [];
  swToRemovePrev: string[] = [];
  swToRemoveRangeSelection01 : string = "";
  swToRemoveRangeSelection02 : string = "";
  modelChanged: Subject<string> = new Subject<string>();
  search = '';
  public isLoading = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { computers: Computer[], title: string, user: ActiveUser },
    private _fb: FormBuilder,
    private _dialogRef: MatDialogRef<SoftwareDeployToComputerComponent>,
    private _dialog: MatDialog,
    private _softwareService : SoftwareService
  ) {
    this.computers = _data.computers;
    this.user = _data.user;
    console.log(this.user, this.user.hasAnyRole("CanWrite"));
    this.computersDescription = this.computers.map(c => c.name).join(", ");
    this.form = this._fb.group({
      computerName: [_data.computers[0].id, { nonNullable: true }],
      description: [_data.computers[0].description, { nonNullable: true }],
      adLocation: [this.simplifyLocation(_data.computers[0].distinguishedName ), { nonNullable: true }]
    })
  }

  async ngOnInit() {
    this.softwareDeployTypes = await this._softwareService.fetchDeployType();
    try {
      await this.loadSoftware();
      this.showSetDesc = true;
      this.modelChanged.pipe(debounceTime(300),distinctUntilChanged())
                       .subscribe(() => {this.searchFor();});
    } catch (ex) {
      console.log(ex)
      this.showSetDesc = false;
    }
  }

  simplifyLocation = (cn: string): string => cn.split(',')
    .map((p: string) => p.replace('OU=Windows', '')
      .replace('OU=Devices', '')
      .replace('OU=Devices', '')
      .replace('DC=iowa', '')
      .replace('DC=uiowa', '')
      .replace('DC=edu', '')
      .replace('OU=', '')
      .replace('CN=', ''))
    .filter(p => p != '')
    .reverse()
    .join('/');

  onSoftwareDeployTypeChange = () => this.loadSoftware();

  async loadSoftware(){
    this.isLoading = true;
    try{
      this.softwareSource=[];
      this.softwareDest=[];
      if (this.softwareDeployType != '' && (this.computers[0].name != null)){
        [this.softwareSource,this.softwareDest] = await this._softwareService.fetchSoftwareForComputer({ deployType: this.softwareDeployType, computerName: this.computers[0].name });
        this.softwareSourceDisplay = this.softwareSource = this.softwareSource.filter(p => !this.softwareDest.find(m=>m.name==p.name) );
      }
    } catch (ex) {
      console.log(ex)
    }
    this.isLoading = false;
  }

  searchFor() {
    if (this.search.length >=1 || this.search.length === 0) {
      this.softwareSourceDisplay = this.softwareSource.filter(p => (p.description?.toLowerCase().indexOf(this.search.toLowerCase())>=0) );
    }
  }

  async summary() {
    var updated = await firstValueFrom
      (this._dialog.open(SoftwareSummaryComponent, { data: {computerName: this.computers[0].id}}).afterClosed());

    if (updated?.length > 0)
      this.dismiss(updated);
  }

  dismiss(result?: any) {
    this._dialogRef.close(result);
  }

  reset () {
    this.search = '';
    this.swToAdd = [];
    this.swToRemove = [];
    this.loadSoftware();  
  } 

  changeModel(event: any) {
    this.modelChanged.next(event.target.value);
  }

  async save() {
      let deploymentType = this.softwareDeployTypes.find(p=>p.type==this.softwareDeployType);
      var updated = await firstValueFrom
        (this._dialog.open(ConfirmSoftwareChangesComponent, { data: {software: this.softwareDest, computerId: this.computers[0].id, deployType: deploymentType }}).afterClosed());

      if (updated?.length > 0)
        this.dismiss(updated);
  }

  async onMoveIntoSelectedList(){
    [this.softwareSource, this.softwareSourceDisplay, this.softwareDest] = 
      await this._softwareService.moveSelectedSoftwareToSelectedList(this.swToAdd, this.softwareSource, this.softwareSourceDisplay, this.softwareDest);
    this.swToAdd.splice(0);
  }

  async onMoveOutSelectedList(){
    [this.softwareSource, this.softwareSourceDisplay, this.softwareDest] = 
      await this._softwareService.moveSelectedSoftwareToAvailableList(this.swToRemove,this.softwareDest, this.softwareSource, this.softwareSourceDisplay);
      this.swToRemove.splice(0);
  }

  onClickSourceList( event: any ): void{
    let clickedItem = this.softwareSourceDisplay.find(x=>x.description==event.target.parentNode.innerText)?.name;

    if (clickedItem == null) return;
    if (!event.shiftKey){
      this.swToAddRangeSelection01 = clickedItem;
      return;
    }

    if (this.swToAddRangeSelection01 !='' && this.swToAddRangeSelection01 != clickedItem) {
      if (this.swToAddRangeSelection02)
        this.multipleRangeSelections(clickedItem);
      else
        this.firstRangeSelection(clickedItem);
    }
  }

  multipleRangeSelections(clickedItem:string){
    let a0 = this.softwareSourceDisplay.findIndex(x=>x.name==this.swToAddRangeSelection01);
    let a1 = this.softwareSourceDisplay.findIndex(x=>x.name==this.swToAddRangeSelection02);
    let a2 = this.softwareSourceDisplay.findIndex(x=>x.name==clickedItem);

    if (Math.abs(a1-a0)<=Math.abs(a2-a0))
      this.firstRangeSelection (clickedItem)
    else if (this.swToAdd.indexOf(this.swToAddRangeSelection01)>=0)
        this.unselectItemsBetween (this.swToAddRangeSelection02, clickedItem, 1);
    else
      this.selectItemsBetween (this.swToAddRangeSelection02, clickedItem);
  }

  firstRangeSelection(clickedItem:string) {
    if (this.swToAdd.indexOf(this.swToAddRangeSelection01)>=0)
      this.selectItemsBetween (this.swToAddRangeSelection01, clickedItem);
    else
      this.unselectItemsBetween (this.swToAddRangeSelection01, clickedItem);
  }

  selectItemsBetween (item1:string, item2:string){
    let a1 = this.softwareSourceDisplay.findIndex(x=>x.name==item1);
    let a2 = this.softwareSourceDisplay.findIndex(x=>x.name==item2);
    if (a1>a2) {let a=a2; a2=a1; a1=a;}  //swap
    for (let i=a1; i<=a2; i++) {
      this.swToAdd.push(this.softwareSourceDisplay[i]?.name); 
    }
    this.swToAdd = [...new Set(this.swToAdd)].sort((a,b)=>{return (a??'') >= (b??'') ? 1:-1});
    this.swToAddRangeSelection02 = item2;
  }

  unselectItemsBetween (item1:string, item2:string, shift=0){
    let a1 = this.softwareSourceDisplay.findIndex(x=>x.name==item1);
    let a2 = this.softwareSourceDisplay.findIndex(x=>x.name==item2);
    if (a1>a2) {let a=a2; a2=a1; a1=a;}  //swap
    this.swToAdd.push( this.softwareSourceDisplay[a1].name);
    this.swToAdd.sort((a,b)=>{return (a??'') >= (b??'') ? 1:-1});
    for (let i=a2+shift-1; i>=a1+shift; i--) {
      this.swToAdd.splice(this.swToAdd.indexOf(this.softwareSourceDisplay[i]?.name),1);
    }    
    this.swToAdd = [...new Set(this.swToAdd)].sort((a,b)=>{return (a??'') >= (b??'') ? 1:-1});
    this.swToAddRangeSelection02 = item2;
  }

  onClickDestList( event : any ): void{
    if (event.shiftKey && this.swToRemoveRangeSelection01 != '' && this.swToRemoveRangeSelection02 != '' &&
        this.swToRemoveRangeSelection01 != this.swToRemoveRangeSelection02 &&
        this.swToRemove.indexOf(this.swToRemoveRangeSelection01)>=0 &&
        this.swToRemove.indexOf(this.swToRemoveRangeSelection02)>=0) {
      let a1 = this.softwareDest.findIndex(x=>x.name==this.swToRemoveRangeSelection01);
      let a2 = this.softwareDest.findIndex(x=>x.name==this.swToRemoveRangeSelection02);
      if (a1>a2) {let a=a2; a2=a1; a1=a;}  //swap
      for (let i=a1; i<=a2; i++) {
        this.swToRemove.push(this.softwareDest[i].name); 
      }
      this.swToRemove = [...new Set(this.swToRemove)].sort((a,b)=>{return (a??'') >= (b??'') ? 1:-1});
    }
  }  
}
