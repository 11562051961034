import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, firstValueFrom, map } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { apiConfiguration } from 'src/environments/environment';
import { Computer } from "../models/computer";
import { ComputerFilter } from "../models/computer-filter";

@Injectable()
export class ComputerResolver  {
    constructor(
        private router: Router,
        private location: Location,
        private http: HttpClient)
    {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Computer[] | void> {
        return this.request(route);
    }

    request(route: ActivatedRouteSnapshot): Observable<Computer[] | void> {
        const p = route.params;
        const q = route.queryParams;
        try {
            if (p["computerName"] === "--selected--") {
                const filter = new ComputerFilter();
                filter.filterBy = 'Keys';
                filter.keywords = q['selected']?.split(',') ?? [];
                    
                return this.http.get<any[]>(apiConfiguration.apiBaseUrl + "computer?" + filter.serialize())
                    .pipe(map(results => results.map((cdp: any | Computer) => new Computer(cdp))));
            }

            const url = apiConfiguration.apiBaseUrl + `computer/${p["computerName"]}`;
            return this.http.get<Computer>(url).pipe(map(c => [new Computer(c)]));
        } catch (err) {
            console.error(err);
            return from(this.notfound(`We cannot find this endpoint. This is likely an app configuration issue.`));
        }
    }

    
    async notfound(msg: string): Promise<Computer[] | void> {
        const path = this.location.path();
        await this.router.navigate(['notfound'], { skipLocationChange: true, queryParams: { message: msg}});
        this.location.replaceState(path);
    }
}
