import { EventEmitter } from '@angular/core';

export type UISpecific = {
    -readonly [K in keyof Computer]: Computer[K];    
    } & {
        changes: EventEmitter<[string, any]>;
    };

export class Computer {
    [key: string]: any;
    constructor(public raw: any) {
        this.id = raw.name;

        this.distinguishedName = this.raw.distinguishedName;
        this.name = this.raw.name;
        this.description = this.raw.description;
        this.location = this.raw.location;
        this.memberOf = this.raw.memberOf;
        this.created = new Date(this.raw.whenCreated);
        this.lastupdated = new Date(this.raw.whenChanged);

        const self = this;
        this.ui = new Proxy<UISpecific>( { ...this, changes: new EventEmitter<[string, any]>() }, {
            set: function(target, prop: string, value, receiver) {
                self[prop] = target[prop] = value;
                target.changes.emit([prop, value]);
                return true;
            }
        });
    }

    readonly ui: UISpecific;
    readonly distinguishedName: string;
    readonly name: string;
    readonly description: string;
    readonly location: string;
    readonly memberOf: string[];
    readonly created: Date;
    readonly lastupdated: Date;

    // Fields for updating/monitoring for changes...
    readonly id: number;

    asApiModel(): Omit<this, keyof UISpecific> {
        const { ui, ...picked } = this;
        return <any>picked;
    }
}