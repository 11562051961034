import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, map, catchError, of } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { ActiveUser } from '../models/active-user';
import { apiConfiguration } from 'src/environments/environment';
import { ErrorService } from "../services/error.service";
import { CommunityAuthService } from "../services/community-auth.service";

@Injectable()
export class UserWithFallbackResolver  {
    constructor(
        private router: Router,
        private location: Location,
        private http: HttpClient,
        private errorService: ErrorService,
        private authService:CommunityAuthService
        ) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ActiveUser|void> {
        return from(this.requestPage(route))
    }

    requestPage(route: ActivatedRouteSnapshot): Observable<ActiveUser|void> {
        const url = apiConfiguration.apiBaseUrl + `user/current`;
        return this.http.get<any[]>(url).pipe(
            map(raw => new ActiveUser(raw)),
            catchError(err => {
                console.log("in the catch for some reason", err);
                return of(new ActiveUser({ hawkId: this.authService.applicationUsername }))
        }));
    }

    async showButStay(loc: string) {
        const path = this.location.path();
        await this.router.navigate([loc], { skipLocationChange: true });
        this.location.replaceState(path);
    }
}
