<mat-dialog-content>
    <h2>{{ title }}</h2>
    <div class="dialog-body">
        <section [formGroup]="form">
            <div class="dialog-body-section-wrapper">
                <div class="org-info">
                    <div class="org-name">
                        <mat-form-field class="org-info-detail">
                            <input type="text" placeholder="Group Name" matInput formControlName="orgName" readonly="true">
                        </mat-form-field>
                    </div>
                    <div class="org-desc">
                        <mat-form-field class="org-info-detail">
                            <input type="text" placeholder='Description' matInput formControlName="description" readonly="true">
                        </mat-form-field>
                    </div>
                    <!-- <div class="org-location">
                        <mat-form-field class="org-info-detail">
                            <input type="text" placeholder="AD Location" matInput formControlName="adLocation" readonly="true">
                        </mat-form-field>
                    </div> -->
                </div>
                <div class="deploy-op">
                    <div>
                        <button mat-raised-button (click)="summary()">Summarize Deployments</button>
                    </div>
                    <mat-radio-group [(ngModel)]="softwareDeployType" [ngModelOptions]="{standalone: true}" (change)="onSoftwareDeployTypeChange()"  class="radio-group">
                        <mat-radio-button class="radio-button" *ngFor="let depType of softwareDeployTypes" [value]="depType.type">
                            {{depType.label}}
                        </mat-radio-button>
                    </mat-radio-group>
                </div>
            </div>
        </section>
        <div *ngIf="isLoading; else done" class="spinner-wrapper">
            <mat-spinner color="primary" class="spinner">
            </mat-spinner>
        </div>
        <ng-template #done>
        <div class="software-selections">
            <div class="software-list">
                <div class="grid-container">
                    <div class="grid-child available-adjust">Available</div>
                    <div class="input-group grid-child">
                        <input type="text"
                        class="form-control"
                        placeholder="Search"
                        aria-label="Search"
                        aria-describedby="Search"
                        [(ngModel)]='search'
                        (keyup) = "changeModel($event)">
                        <div class="input-group-append">
                            <button *ngIf='search'(click)="search = ''; changeModel($event)" class="btn btn-primary" type="button">X</button>
                        </div>
                    </div>
                </div>
                <cdk-virtual-scroll-viewport [itemSize]="softwareSourceDisplay.length" class="scroll-viewport">
                    <mat-selection-list  [(ngModel)]="swToAdd">
                        <mat-list-option *ngFor="let software of softwareSourceDisplay" class="scroll-viewport-item" [value]="software.name" aria-selected="true">
                            {{software.description}}
                        </mat-list-option>
                    </mat-selection-list>
                </cdk-virtual-scroll-viewport>
                <p>
                    Selected: {{swToAdd.length}}
                </p>
            </div>
            <div class="select-buttons">
                <div class="select-button">
                    <button mat-button (click)="onSelect()" title="Edit subscriptions" >
                        <mat-icon>arrow_forward</mat-icon>
                    </button>
                </div>
                <div class="deselect-button">
                    <button mat-button (click)="onDeselect()" title="Edit subscriptions">
                        <mat-icon>arrow_backward</mat-icon>
                    </button>
                </div>
            </div>
            <div class="software-list-synced">Selected
                <cdk-virtual-scroll-viewport [itemSize]="softwareDest.length" class="scroll-viewport">
                    <mat-selection-list [(ngModel)]="swToRemove">
                        <mat-list-option *ngFor="let sw of softwareDest" class="scroll-viewport-item" [value]="sw.name" aria-selected="true">
                            {{sw.description}}
                        </mat-list-option>
                    </mat-selection-list>
                </cdk-virtual-scroll-viewport>
                <p>
                    Selected: {{swToRemove.length}}
                </p>
            </div>
        </div>
        </ng-template>        
    </div>
</mat-dialog-content>
<div *ngIf="!isLoading">
<mat-dialog-actions align="end">
    <button mat-raised-button (click)='reset()'>Reset Form</button>
    <button mat-raised-button (click)='dismiss()'>Dismiss</button>
    <button mat-raised-button (click)='save()' *ngIf="user.hasAnyRole('CanWrite')">Sync</button>
</mat-dialog-actions>
</div>