import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA as MAT_DIALOG_DATA, MatDialogRef as MatDialogRef, MatDialog as MatDialog } from '@angular/material/dialog';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { combineLatest, firstValueFrom, map, Observable } from 'rxjs';
import { ActiveUser } from 'src/app/models/active-user';
import { Computer } from 'src/app/models/computer';
import { OrgStructure, OrgStructureDescriptor } from 'src/app/models/org-structure';
import { actionCodeLookup, SubscriptionDelta } from 'src/app/models/subscription-delta';
import { MemberOfUtilityService } from 'src/app/services/memberof-utility.service';
import { OrgStructurePipesFactory } from 'src/app/services/org-struct-pipes.factory';
import { apiConfiguration } from 'src/environments/environment';
import { ConfirmChangesComponent } from '../confirm-changes/confirm-changes.component';

type NewType = OrgStructureDescriptor;

@Component({
  selector: 'app-edit-org-structure',
  templateUrl: './edit-org-structure.component.html',
  styleUrls: ['./edit-org-structure.component.scss']
})
export class EditOrgStructureComponent implements OnInit {
  title: string = "Edit Org Structure";
  computer!: string;
  computers!: Computer[];
  computersDescription!: string;
  form!: FormGroup;
  filtered: { [key: string]: Observable<NewType[]> } = {};
  showSetDesc: boolean = false;
  computedDesc: string = "";
  user: ActiveUser;
  updateEnabled: boolean = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private _data: { computers: Computer[], options: OrgStructure, title: string, user: ActiveUser },
    private _fb: FormBuilder,
    private _http: HttpClient,
    private _dialogRef: MatDialogRef<EditOrgStructureComponent>,
    private _dialog: MatDialog,
    private _orgStructFilterFactory: OrgStructurePipesFactory,
    private _memberOfUtil: MemberOfUtilityService
  ) {
    this.computers = _data.computers;
    this.user = _data.user;
    // console.log(this.user, this.user.hasAnyRole("CanWrite"));
    this.computersDescription = this.computers.map(c => c.name).join(", ");
    const orgDefault = this.setupDefault('org');
    const deptDefault = this.setupDefault('dept');
    const unitDefault = this.setupDefault('unit');
    const bldgDefault = this.setupDefault('bldg');
    this.form = this._fb.group({
      description: [_data.computers[0].description, { nonNullable: true }],
      location: [_data.computers[0].location, { nonNullable: true }],
      org: [''], dept: [''], unit: [''], bldg: [''],
      orgSelect: [orgDefault, { nonNullable: true }],
      deptSelect: [deptDefault, { nonNullable: true }],
      unitSelect: [unitDefault, { nonNullable: false }],
      bldgSelect: [bldgDefault, { nonNullable: true }]
    });
    this.form.valid
  }

  async ngOnInit() {
    const options = this._data.options;
    if (this.computers.length > 1) {
      for (let key in options) {
        if (!options[key].find(o => o.name === 'various'))
          options[key].push(new OrgStructureDescriptor({ description: "--Various--", shortName: () => '', name: 'various', ui: { selected: false } }));
      }
    }
    if(!options.DEPT.find(x=>x.description == '--None--'))
      options.DEPT.push(new OrgStructureDescriptor({ description: "--None--", shortName: () => '', name: '', ui: { selected: false } }));
    if(!options.UNIT.find(x=>x.description == '--None--'))
      options.UNIT.push(new OrgStructureDescriptor({ description: "--None--", shortName: () => '', name: '', ui: { selected: false } }));
    this.filtered = this._orgStructFilterFactory.build(this.form, options);
    for (let key in this.filtered) {
      this.filtered[key] = this.filtered[key].pipe(
        map(orgs => orgs.filter(org => this.byRelated(key.toUpperCase(), org))),
        map(orgs => orgs.sort(this.sortBySelectedThenNameDesc)),
       
        
      );
      
    }

    // this._data.options.UNIT.push(new OrgStructureDescriptor({ description: "--None--", shortName: () => '', name: 'none', ui: { selected: false } }));

    // console.log(this.filtered);
    try {
      this.computedDesc = await firstValueFrom(this._http.get(apiConfiguration.apiBaseUrl + `OrgStructure/${this._data.computers[0].name}/default-desc`, { responseType: 'text' }));
      this.showSetDesc = true;
    } catch (ex) {
      this.showSetDesc = false;
    }
  }

  private byRelated(which: string, o: OrgStructureDescriptor) {
    if (which == 'BLDG' || which == 'ORG' || o.name == 'various'|| o.description == '--None--')
      return true;

    let org = this.form.get('orgSelect')?.value?.toUpperCase() ?? '';
    org = org == '' ? null : org.substring(org.indexOf("-") + 1);
    if (which == "DEPT") {
      return o.name?.toUpperCase().startsWith(`DEPT-${org ?? ''}`);
    }

    let dept = this.form.get('deptSelect')?.value?.toUpperCase() ?? '';
    dept = dept == '' ? null : dept.substring(dept.lastIndexOf("-") + 1);
    const matcher = new RegExp(`^UNIT-${org ?? '.+'}-${dept ?? '.+'}`);
    return o.name != null && matcher.test(o.name.toUpperCase());
  }

  setupDefault(which: string): string {
    const def = this._memberOfUtil.getComputerDefaultForKey(this.computers[0], which);
    // console.log(def, which);
    const isHomogenous = this.computers.reduce((isHom, comp) => isHom && def == this._memberOfUtil.getComputerDefaultForKey(comp, which), true);
    if (!isHomogenous && this.computers.length > 1) {
      return "various";
    }
    const results = /^CN=(.+?),/.exec(def);
    if (!results) {
      console.warn(`Couldn't parse: ${def}.`);
      return "";
    }

    return results[1];
  }

  setSelection(which: string, change: MatSelectChange) {
    for (let op of this._data.options[which.toUpperCase()]) {
      op.ui.selected = op.name === change.value;
      // console.log('change', change.value, op.ui.selected);
      
    }
    if (which === 'dept') {
      // console.log("cleared")
      this.form.get('unitSelect')?.setValue('');
      this.form.get('unit')?.setValue('');
      // console.log(this.form.get('unitSelect')?.value);
    }
    this.form.get(which)?.setValue('');
  }

  sortBySelectedThenNameDesc(a: OrgStructureDescriptor, b: OrgStructureDescriptor) {
    if (a.description == '--None--') return -1;
    if (a.ui.selected) return -1;
    if (b.ui.selected) return 1;
    if (!a.name) return 1;
    if (!b.name) return -1
    return a.name < b.name ? -1 : 1;
  }

  dismiss(result?: any) {
    this._dialogRef.close(result);
  }

  reset() {
    this.form.reset();
    this.form.reset();
    this.updateEnabled = false;
  }

  async save() {
    const model = {
      name: this.computers[0].name,
      description: this.form.get('description')?.value,
      location: this.form.get('location')?.value,
      memberOf: Array.from(this.computers[0].memberOf)
    };
    // console.log('save model', model);

    type stringDict = { [key:string]: string};
    type stringArrayDict = { [key: string]: (string | undefined)[]}
    const proposedMemberships = ["org", "dept", "unit", "bldg"]
      .reduce(
        (dict: stringDict, gtype) => { dict[gtype.toUpperCase()] = this.form.get(`${gtype}Select`)?.value; return dict; },
        <stringDict>{});
    const availableGroups = ["org", "dept", "unit", "bldg"]
        .reduce(
          (dict: stringArrayDict, gtype) => {
            dict[gtype.toUpperCase()] = this._data
              .options[gtype.toUpperCase()]
              .map(o => `CN=${o.name},OU=${gtype.toUpperCase()},OU=Institutional Structure,OU=SCCM (Discovery),OU=Groups,OU=Device Management,OU=Services,DC=iowa,DC=uiowa,DC=edu`);
            return dict;
          },
          <stringArrayDict>{}
        );
    // console.log(availableGroups, this._data.options);
    const deltas = this._memberOfUtil.generateSubscriptionDeltas(this.computers, proposedMemberships, availableGroups);
    let localUpdates: (Computer | any)[];
    let updated: Computer[];
    if (this.computers.length === 1) { // Then also update the description and location
      localUpdates = [ { ...this.computers[0], ...model } ];
      updated = await firstValueFrom(this._dialog.open(ConfirmChangesComponent, { data: { deltas, model, localUpdates, computer: this.computers[0] }}).afterClosed());
    } else {
      localUpdates = this.computers.map(c => { return { ...c }; });
      updated = await firstValueFrom(this._dialog.open(ConfirmChangesComponent, { data: { deltas, localUpdates }}).afterClosed());
    }
    if (updated?.length > 0)
      this.dismiss(updated);
  }
  
  async generateDescription() {
    this.form.get('description')?.setValue(this.computedDesc);
    this.form.get('description')?.markAsDirty();
  }
}
